<script lang="ts" setup>
import { CheckCircleIcon, ShieldCheckIcon } from '@heroicons/vue/24/solid';
import { computed } from 'vue';
import { theme } from '@app/customer/Composables/useTheme';
import { type Types } from '@app/shared/types/generated-v2';

type ShippingMethodOptionViewModel = Types['App.Http.ViewModels.Customer.ShippingMethodOptionViewModel'];
type CountryViewModel = Types['App.Models.ViewModels.CountryViewModel'];
type InsurancePackageViewModel = Types['App.Models.ViewModels.Customer.InsurancePackageViewModel'];

const props = defineProps<{
  fromCountry: null | CountryViewModel;
  shippingMethodOption: null | ShippingMethodOptionViewModel;
  insurancePackage: null | InsurancePackageViewModel;
  checked: boolean;
}>();

const emit = defineEmits(['select']);

function select() {
  emit('select');
}

const borderClassList = computed(() => {
  return [
    { 'border-blue-500 bg-blue-50': props.checked },
    { 'hover:border-slate-400': !props.checked },
    theme('outline'),
  ];
});

const price = computed(() => {
  if (!props.shippingMethodOption) {
    return null;
  }

  return props.shippingMethodOption.countryId === props.fromCountry.id
    ? props.insurancePackage.domesticPrice
    : props.insurancePackage.internationalPrice;
});
</script>

<template>
  <div
    tabindex="0"
    class="border-slate-300 border rounded-lg shadow-sm p-4 space-y-4 text-sm cursor-pointer"
    :class="borderClassList"
    @click="select"
    @keyup.enter="select"
  >
    <div class="flex space-x-2 items-center w-full text-sm">
      <div>
        <CheckCircleIcon
          v-if="checked"
          class="h-5 w-5 fill-blue-500"
        />
        <div
          v-else
          class="w-5 h-5 rounded-full outline outline-gray-400 outline-1 outline-offset-[-3px]"
        />
      </div>
      <div
        v-if="insurancePackage"
        class="flex justify-between items-center w-full"
      >
        <div class="flex items-center space-x-2">
          <ShieldCheckIcon class="text-brand-500 w-5 h-5" />
          <span class="text-sm">
            {{ $t('customer.shipping:insurance:protection') }}
            <span class="font-semibold">{{ insurancePackage.insuredValue }}</span>
          </span>
        </div>
        <div>
          <span class="text-sm text-gray-600">{{ price }}</span>
        </div>
      </div>
      <span
        v-else
        class="text-sm"
      >
        {{ $t('customer.shipping:insurance:none') }}
      </span>
    </div>
  </div>
</template>
