<script lang="ts" setup>
import {
  ChevronDownIcon,
  ChevronUpIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from '@heroicons/vue/24/outline';
import { Link, usePage } from '@inertiajs/vue3';
import {
  Toast,
  ToastGroup,
  ToastVariant,
  Popover,
} from '@app/customer/Components';
import { useToast } from '../Composables/useToast';
import { router } from '@inertiajs/vue3';
import {
  computed,
  onMounted,
  onUnmounted,
  PropType,
  ref,
} from 'vue';
import { useI18n } from 'vue-i18n';
import { asset } from 'laravel-vapor';
import { theme } from '@app/customer/Composables/useTheme';
import { useLocale } from '@app/customer/Composables/useLocale';
import { AppLayoutWidth } from './types';
import { ReturnlessWatermarkIcon } from '@app/customer/Icons';
import * as Types from '@app/panel/types/generated';
import { useScrollAfterNavigation } from '@app/customer/Composables/useScrollAfterNavigation';

type FormLocaleViewModel = Types.App.Models.ViewModels.FormLocaleViewModel;

const props = defineProps({
  previousPageUrl: {
    type: String,
    default: null,
  },

  nextPageUrl: {
    type: String,
    default: null,
  },

  width: {
    type: String as PropType<AppLayoutWidth>,
    default: null,
    validator: (val: AppLayoutWidth): boolean =>
      Object.values(AppLayoutWidth).includes(val),
  },

});

const websiteUrl = computed<string | null>(() => {
  return usePage().props.websiteUrl as string | null;
});

const layoutWidth = computed<string>(() => {
  switch (props.width) {
    case AppLayoutWidth.Medium:
      return 'max-w-3xl mx-auto w-full';
    case AppLayoutWidth.Large:
      return 'max-w-4xl mx-auto w-full';
    default:
      return '';
  }
});

const {
  emitToastEvent,
  closeToast,
  startListeningToastEvents,
  toastIDs,
  toastNotifications,
} = useToast();

const { t } = useI18n();

let removeInertiaExceptionHandler = null;

function startInertiaExceptionHandler(): void {
  removeInertiaExceptionHandler = router.on('exception', (event) => {
    if (!navigator.onLine || event.detail.exception.message === 'Network Error') {
      emitToastEvent({
        title: t('customer.global:offline'),
        message: t('customer.global:internet-disconnected'),
        variant: ToastVariant.Critical,
      });

      event.preventDefault();
    }
  });
}

onMounted(() => {
  startListeningToastEvents();
  startInertiaExceptionHandler();
  setAnchorTargets();
});

function setAnchorTargets(): void {
  document.querySelectorAll('a[href]').forEach((anchor: HTMLAnchorElement) => {
    if (anchor.hostname !== window.location.hostname) {
      anchor.setAttribute('target', '_blank');
    }
  });
}

onUnmounted(() => {
  removeInertiaExceptionHandler();
});

const isLanguageSelectorOpen = ref<boolean>(false);

function toggleLanguageSelector(): void {
  isLanguageSelectorOpen.value = !isLanguageSelectorOpen.value;
}

function closeLanguageSelector(): void {
  isLanguageSelectorOpen.value = false;
}

const { currentLocale, changeLocale } = useLocale();

const formLocales = computed<FormLocaleViewModel[]>(() => {
  return usePage().props.formLocales as FormLocaleViewModel[];
});

const logoImage = computed<string>(() => {
  return usePage().props.logoImageSrc as string | null
    ?? asset('img/form-placeholder-images/logo.svg');
});

const coverImage = computed<string | null>(() => {
  return usePage().props.coverImageSrc as string | null;
});

const isWatermarkEnabled = computed<boolean>(() => {
  return usePage().props.showWatermark as boolean;
});

useScrollAfterNavigation();
</script>

<template>
  <ToastGroup>
    <Toast
      v-for="toast in toastNotifications"
      :key="toast.id"
      :open="toastIDs.includes(toast.id)"
      :title="toast.title"
      :message="toast.message"
      :variant="toast.variant"
      :duration="toast.duration"
      @close="() => closeToast(toast.id)"
    />
  </ToastGroup>

  <div class="flex min-h-full flex-col">
    <div
      v-if="$page.props.isTestMode"
      class="relative top-0 z-10 w-full bg-blue-500 p-1 text-center text-xs text-white"
    >
      {{ $t('customer.global:form:test-mode') }}
    </div>

    <div class="grid grow grid-cols-1">
      <div class="flex h-full">
        <div
          v-if="$route().current('customer.form.show') && formLocales.length > 1"
          class="absolute z-10 ml-4 mt-4"
        >
          <button
            class="inline-flex items-center rounded-md px-2.5 py-1.5 text-center text-sm font-medium hover:bg-slate-100"
            :class="theme('outline')"
            @click="toggleLanguageSelector"
            @keyup.esc="closeLanguageSelector"
          >
            <div class="flex size-full items-center space-x-2">
              <div :class="`fflag fflag-${currentLocale.countryCode.toUpperCase()} ff-sm`" />

              <ChevronDownIcon
                v-if="! isLanguageSelectorOpen"
                class="size-3 text-slate-900"
              />

              <ChevronUpIcon
                v-if="isLanguageSelectorOpen"
                class="size-3 text-slate-900"
              />
            </div>
          </button>

          <Popover
            :open="isLanguageSelectorOpen"
            @close="closeLanguageSelector"
          >
            <div
              v-for="formLocale in formLocales"
              :key="formLocale.id"
              class=" w-full cursor-pointer p-2 hover:bg-slate-100"
              @click="() => changeLocale(formLocale.domain, formLocale.locale)"
            >
              <div :class="`fflag fflag-${formLocale.locale.countryCode.toUpperCase()} ff-lg`" />
            </div>
          </Popover>
        </div>

        <div class="relative mx-auto flex size-full max-w-lg flex-col px-4 pt-12 sm:w-9/12 sm:max-w-none sm:px-0 md:px-12 lg:px-24 xl:mr-[30rem] xl:px-40">
          <Link
            v-if="previousPageUrl !== null"
            :href="previousPageUrl"
            no-underline
          >
            <ChevronLeftIcon class="absolute left-6 top-6 size-6 text-slate-900" />
          </Link>

          <Link
            v-if="nextPageUrl !== null"
            :href="nextPageUrl"
            no-underline
          >
            <ChevronRightIcon class="absolute right-6 top-6 size-6 text-slate-900" />
          </Link>

          <div class="mx-auto mb-16 mt-10 max-h-24 max-w-xs md:my-12">
            <a
              v-if="websiteUrl"
              :href="websiteUrl"
              target="_blank"
            >
              <img
                v-fallback-img
                data-id="logo-image"
                data-type="image"
                class="mx-auto mb-0 h-auto max-h-24 w-full max-w-60 object-contain md:mb-12 md:max-w-xs"
                :src="logoImage"
                alt="brand-logo"
                loading="lazy"
              >
            </a>

            <img
              v-if="! websiteUrl"
              v-fallback-img
              data-id="logo-image"
              data-type="image"
              class="mx-auto mb-0 h-auto max-h-24 w-full max-w-60 object-contain md:mb-12 md:max-w-xs"
              :src="logoImage"
              alt="brand-logo"
              loading="lazy"
            >
          </div>

          <div
            :class="layoutWidth"
            class="pb-8"
          >
            <slot />
          </div>

          <div
            v-if="isWatermarkEnabled"
            class="flex flex-1 items-end justify-center pb-4"
          >
            <div class="space-y-1 text-center">
              <a
                href="https://www.returnless.com/?utm_source=form&utm_medium=referral"
                target="_blank"
                class="group relative inline-block w-32 md:w-36 lg:w-44 "
              >
                <ReturnlessWatermarkIcon class="fill-slate-200 group-hover:fill-slate-300" />
              </a>
            </div>
          </div>
        </div>

        <div class="hidden xl:block">
          <div
            class="fixed bottom-0 right-0 h-full w-[30rem] overflow-hidden bg-slate-100"
            style="clip-path: ellipse(58% 100% at 60% 50%);"
          >
            <img
              v-fallback-img
              data-id="cover-image"
              data-type="image"
              class="size-full object-cover"
              :src="coverImage"
              alt="cover-image"
              loading="lazy"
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
html, body, #app {
  height: 100%;
}
</style>
