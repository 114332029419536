<script lang="ts" setup>
import { CloudIcon, XCircleIcon } from '@heroicons/vue/24/outline';

defineProps({
  name: {
    type: String,
    required: true,
  },

  progress: {
    type: Number,
    default: 0,
  },
});

const emit = defineEmits<{ (e: 'remove'): void }>();

function remove(): void {
  emit('remove');
}
</script>

<template>
  <div
    class="flex items-center py-2 border border-slate-300 rounded-md relative overflow-hidden"
  >
    <div
      class="absolute bg-slate-200 h-full"
      :style="{ width: `${progress*100}%` }"
    />

    <div class="h-5 w-5 relative">
      <CloudIcon class="h-5 w-5 ml-1 text-slate-400" />
    </div>

    <span class="text-xs ml-2 relative text-slate-500">
      {{ name }}
    </span>

    <div class="h-5 w-5 ml-auto mr-2 relative">
      <XCircleIcon
        class="w-5 h-5 text-slate-400 hover:text-slate-500 cursor-pointer"
        @click="remove"
      />
    </div>
  </div>
</template>
