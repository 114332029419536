import { router } from '@inertiajs/vue3';
import { nextTick, onUnmounted } from 'vue';

export function useScrollAfterNavigation() {
  onUnmounted(router.on('success', () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }));

  onUnmounted(router.on('error', () => {
    nextTick(() => {
      nextTick(() => {
        document.querySelector('.banner, .error-message')?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      });
    });
  }));
}
