<script lang="ts" setup>
import {
  computed,
} from 'vue';
import { validateColumnsNumber } from '../helpers/validateColumnsNumber';

const props = defineProps({
  columns: {
    type: Number,
    validator: (val: number): boolean => validateColumnsNumber(val),
    default: 1,
  },

  columnsSm: {
    type: Number,
    validator: (val: number): boolean => validateColumnsNumber(val),
    default: 1,
  },
});

const columnsClass = computed(() => {
  return `grid-cols-${props.columns}`;
});

const columnsSmClass = computed(() => {
  return props.columnsSm ? `sm:grid-cols-${props.columnsSm}` : '';
});

const classList = computed(() => {
  return [
    columnsClass.value,
    columnsSmClass.value,
  ];
});
</script>

<template>
  <div
    class="grid gap-4"
    :class="classList"
  >
    <slot />
  </div>
</template>
