import * as Types from '@app/panel/types/generated';

type ProductDetailType = Types.App.Enums.ProductDetailType;

export function productDetailsService(productDetails: ProductDetailType[]) {
  function isVisible(productDetail: ProductDetailType): boolean {
    return productDetails.includes(productDetail);
  }

  return { isVisible };
}
