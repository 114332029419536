<script lang="ts" setup>
import { onMounted } from 'vue';
import ProgressItem from './ProgressItem.vue';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/vue/24/outline';
import { ref } from 'vue';
import { ProgressBlock } from '../types';

defineProps<{ blocks: ProgressBlock[] }>();

const slidesContainer = ref(null);
const slides = ref(null);

onMounted(() => {
  slides.value = document.querySelectorAll('.slide');
});

let counter = 0;

function scrollToCenter(parent, child) {
  if (!parent || !child) {
    return;
  }

  const parentRect = parent.getBoundingClientRect();
  const childRect = child.getBoundingClientRect();

  const scrollLeft = (childRect.left + childRect.right) / 2 - (parentRect.left + parentRect.right) / 2;

  parent.scroll({
    top: 0,
    left: parent.scrollLeft + scrollLeft,
    behavior: 'smooth',
  });
}

function next() {
  if (counter < slides.value.length - 1) {
    counter++;
  }

  scrollToCenter(slidesContainer.value, slides.value[counter]);
}

function prev() {
  if (counter > 0) {
    counter--;
  }

  scrollToCenter(slidesContainer.value, slides.value[counter]);
}
</script>

<template>
  <section class="relative">
    <button
      class="absolute top-0 bottom-0 -left-4 m-auto"
      @click="prev"
    >
      <ChevronLeftIcon class="h-8 w-8 text-slate-700" />
    </button>
    <button
      class="absolute top-0 bottom-0 -right-4"
      @click="next"
    >
      <ChevronRightIcon class="h-8 w-8 text-slate-700" />
    </button>
    <div class="m-4 relative overflow-hidden">
      <ul
        ref="slidesContainer"
        class="slides-container h-[15vh] w-full flex list-none m-0 p-0 overflow-scroll scroll-smooth"
      >
        <li
          v-for="(block, index) in blocks"
          :key="index"
          class="slide w-8/12 flex-[1_0_80%] flex h-full items-center px-4"
        >
          <ProgressItem v-bind="block" />
        </li>
      </ul>
    </div>
  </section>
</template>

<style scoped>
.slides-container {
    scrollbar-width: none;
}

.slides-container::-webkit-scrollbar {
    width: 0;
    height: 0;
}
</style>
