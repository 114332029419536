import { ref } from 'vue';
import { useApi } from '@app/customer/Composables/useApi';

export function useSmartPolling() {
  const pollingAttempts = ref(0);
  const pollingIntervalInMs = ref(500); // Start with 500ms
  const maxPollingIntervalInMs = ref(5000); // After attempt 8, max interval will be 5 seconds
  const maxPollingAttempts = ref(25); // Approx 100 seconds

  function nextAttempt(uri: string, onSuccess: (response: any) => boolean, onFailure: (() => void) | null = null): void {
    // Increase attempts
    pollingAttempts.value++;

    // Stop with polling after the axPollingAttempts (approx 100 seconds)
    if (pollingAttempts.value > maxPollingAttempts.value) {
      onFailure?.call(this);
      return;
    }

    // Increase polling interval until maxPollingIntervalInMs
    pollingIntervalInMs.value = (pollingAttempts.value % 2 === 0)
      ? Math.min(maxPollingIntervalInMs.value, pollingIntervalInMs.value * 2)
      : pollingIntervalInMs.value;

    // Try again with new interval
    setTimeout(() => poll(uri, onSuccess, onFailure), pollingIntervalInMs.value);
  }

  function poll(uri: string, onSuccess: (response: any) => boolean, onFailure: (() => void) | null = null): void {
    useApi().get(uri)
      .then((response) => {
        if (onSuccess(response) === true) {
          return;
        }

        nextAttempt(uri, onSuccess, onFailure);
      })
      .catch(() => {
        nextAttempt(uri, onSuccess, onFailure);
      });
  }

  return {
    poll,
  };
}
