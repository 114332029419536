import type { Types } from '@app/shared/types/generated-v2';
import { asset } from 'laravel-vapor';

type PaymentMethodViewModel = Types['App.Models.ViewModels.PaymentMethodViewModel'];

export function paymentMethodService() {
  function getPaymentMethodLogo(paymentMethod: PaymentMethodViewModel): string {
    return asset(`img/payment-methods/${paymentMethod.systemName.toLowerCase()}.svg`);
  }

  return { getPaymentMethodLogo };
}
