<script lang="ts" setup>
import { computed, ref } from 'vue';
import { DropZone } from './index';
import { DocumentPlusIcon } from '@heroicons/vue/24/outline';
import { ErrorMessage } from '../../ErrorMessage';
import { HelpText } from '../../HelpText';
import InputLabel from '../../InputLabel/components/InputLabel.vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const {
  allowedFileTypes = [],
  error = null,
  fileSizeLimit = null,
  label = null,
  multiple = false,
  onFilesUpload,
} = defineProps<{
  allowedFileTypes?: string[];
  error?: string | null;
  fileSizeLimit?: number | null;
  label?: string | null;
  multiple?: boolean;
  onFilesUpload: (files: File[]) => void;
}>();

const internalError = ref<string | null>(null);

const errorMessage = computed<string | null>(() => error || internalError.value);

const formattedFileTypes = computed<string>(() => {
  return allowedFileTypes.map((fileType) => {
    return fileType.split('/').pop().toUpperCase();
  }).join(', ');
});

const accept = computed<string>(() => {
  return allowedFileTypes.join(',');
});

const formattedFileSizeLimit = computed<number>(() => {
  return fileSizeLimit / 1024 / 1024;
});

function onInputChange(e: InputEvent): void {
  const target = e.target as HTMLInputElement;

  onBeforeUpload([...target.files]);

  target.files = null;
  (e.target as HTMLInputElement).value = null;
}

const fileInput = ref(null);

function openFileUploadBrowser(): void {
  internalError.value = null;
  fileInput.value.click();
}

function onBeforeUpload(files: File[]) {
  if (files.some((file) => file.size > fileSizeLimit)) {
    internalError.value = t('customer.global:file-upload:too-large');
  }

  onFilesUpload(files);
}
</script>

<template>
  <div>
    <InputLabel
      v-if="label"
      :label="label"
      :flush="!! $slots['help-text']"
    />

    <HelpText v-if="!! $slots['help-text']">
      <slot name="help-text" />
    </HelpText>

    <DropZone
      v-slot="{ dropZoneActive }"
      @files-dropped="onBeforeUpload"
    >
      <div
        class="mt-1 flex cursor-pointer flex-col space-y-4 rounded-md border border-dashed px-6 pb-6 pt-5"
        :class="{
          'border-slate-400 bg-slate-50': dropZoneActive,
          'border-red-300': errorMessage,
          'border-slate-300': !errorMessage,
        }"
        @click="openFileUploadBrowser"
      >
        <div class="flex flex-col items-center space-y-1 text-center">
          <DocumentPlusIcon class="mx-auto size-12 text-slate-400" />

          <div class="flex text-sm text-slate-600">
            <div
              class="relative cursor-pointer rounded-md bg-white font-medium"
              @click.stop="openFileUploadBrowser"
            >
              <div>
                <span class="text-blue-600 hover:underline">
                  {{ $t('customer.global:file-upload:click-here') }}
                </span>

                <span class="font-normal">&nbsp;{{ $t('customer.global:file-upload:drag-and-drop') }}</span>
              </div>

              <div v-if="allowedFileTypes && fileSizeLimit">
                <span class="text-xs font-normal text-slate-300">{{ formattedFileTypes }} {{ $t('customer.global:file-upload:up-to') }} {{ formattedFileSizeLimit }}MB</span>
              </div>

              <input
                ref="fileInput"
                name="file-upload"
                type="file"
                class="sr-only"
                :accept="accept"
                :multiple="multiple"
                @change="onInputChange"
              >
            </div>
          </div>
        </div>
      </div>

      <ErrorMessage
        v-if="errorMessage"
        :message="errorMessage"
      />
    </DropZone>
  </div>
</template>
