import { UploadedFileInterface } from './types';

export default class UploadedFile implements UploadedFileInterface {
  file: File;
  id: string;
  name: string;
  url: string;
  progress: number | null;
  type: string;
  size: number;

  constructor(file: File) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.name = file.name;
    this.url = URL.createObjectURL(file);
    this.progress = null;
    this.type = file.type;
    this.size = file.size;
  }
}
