import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import VueClickAway from 'vue3-click-away';
import Vapor from 'laravel-vapor';
import * as Sentry from '@sentry/vue';
import { getEnv } from '@app/shared/Utils';
import { createI18n } from './Utils';
import { fallbackImage } from '@app/shared/Directives';
import ignoreErrors from '@app/shared/config/sentry-errors';

Vapor.withBaseAssetUrl(import.meta.env.VITE_VAPOR_ASSET_URL);
(globalThis as any).FOCUSUI_DARKMODE_SUPPORTED = false;

// noinspection JSIgnoredPromiseFromCall
createInertiaApp({
  progress: {
    delay: 0,
    showSpinner: true,
    color: '#2b2bd1',
  },
  resolve: (name) => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue', { eager: true })),
  setup({ el, App, props, plugin }) {
    const language = <string>props.initialPage.props.locale;

    const i18n = createI18n(language);

    const application = createApp({
      render: () => h(App, props),
    });

    application
      .use(VueClickAway)
      .use(i18n)
      .use(plugin)
      .directive('fallback-img', fallbackImage);

    application.config.globalProperties.$route = route;

    Sentry.init({
      ignoreErrors,
      app: application,
      dsn: getEnv('sentry_dns'),
      integrations: [],
    });

    application.mount(el);

    return application;
  },
});
