<script lang="ts" setup>
import { h } from 'vue';
import { type ActionListItem } from '../types';

const props = defineProps<{ items: ActionListItem[] }>();

const ActionList = () => props.items.map((item) => {
  return h('div', {
    class: 'flex items-center whitespace-nowrap block p-2 text-sm rounded-md cursor-pointer hover:bg-slate-100',
    onClick: item.onClick ? item.onClick : null,
  }, item.content);
});
</script>

<template>
  <ActionList />
</template>
