import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';

interface BrandColor {
  value: string;
  contrast: string;
  blend: string;
  border: string | null;
}

export function useBrandColor() {
  return computed(() => {
    const {
      blend: hover,
      border: border,
      contrast: foreground,
      value: background,
    } = usePage().props.brandColor as BrandColor;

    return {
      background,
      border,
      foreground,
      hover,
    };
  });
}
