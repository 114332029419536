<script lang="ts" setup>
import { h } from 'vue';
import ProgressItem from './ProgressItem.vue';
import { type ProgressBlock } from '../types';

const props = defineProps<{ blocks: ProgressBlock[] }>();

const ProgressBlocks = () => {
  return h('div', { class: 'flex overflow-x-auto break-all gap-4' }, props.blocks.map((block, index) => {
    return h(ProgressItem, {
      first: index === 0,
      last: index === props.blocks.length - 1,
      ...block,
    });
  }));
};
</script>

<template>
  <ProgressBlocks />
</template>
