import { usePage } from '@inertiajs/vue3';
import { computed } from 'vue';
import * as Types from '@app/panel/types/generated';

type FormLocaleViewModel = Types.App.Models.ViewModels.FormLocaleViewModel;
type LocaleViewModel = Types.App.Models.ViewModels.LocaleViewModel;
type DomainViewModel = Types.App.Models.ViewModels.DomainViewModel;

export function useLocale() {
  const currentLocale = computed<LocaleViewModel>(() => {
    const formLocale = usePage().props.formLocale as FormLocaleViewModel;
    return formLocale.locale;
  });

  function changeLocale(domain: DomainViewModel, locale: LocaleViewModel): void {
    window.location.href = `${window.location.protocol}//${domain.domain}/${locale.locale}`;
  }

  return {
    currentLocale,
    changeLocale,
  };
}
