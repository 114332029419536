<script lang="ts" setup>
import { computed } from 'vue';
import { Note, NoteForm } from '@app/customer/Containers';
import * as Types from '@app/panel/types/generated';

const props = defineProps<{
  notes: Types.App.Http.ViewModels.Customer.ShowOrderStatusViewModel['notes'];
  readonly: boolean;
}>();

const hasNotes = computed((): boolean => {
  return props.notes && props.notes.length > 0;
});

</script>

<template>
  <div>
    <div
      v-if="hasNotes"
      class="space-y-4"
    >
      <Note
        v-for="note in notes"
        :key="note.id"
        v-bind="note"
      />
    </div>

    <div
      v-if="! readonly"
      class="mt-4"
    >
      <NoteForm />
    </div>
  </div>
</template>
