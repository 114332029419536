<script lang="ts" setup>
import {
  Button,
  ButtonGroup,
  Form,
  FormLayout,
  Heading,
  HeadingElement,
  Select,
  SelectOption,
  TextInput,
  TextStyle,
  TextStyleFontSize,
} from '@app/customer/Components';
import { AppLayout } from '@app/customer/Layouts';
import { useForm } from '@inertiajs/vue3';
import { type Types } from '@app/shared/types/generated-v2';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { computed, watch } from 'vue';

type Country = Types['App.Models.ViewModels.Customer.CountryViewModel'];
type EditAddressViewModel = Types['App.Http.ViewModels.Customer.EditAddressViewModel'];

const props = defineProps<EditAddressViewModel>();

const countrySelectOptions = props.countries.map((country: Country): SelectOption => {
  return {
    label: country.localeName,
    value: country.id,
  };
});

const form = useForm({
  name: props.customerName,
  phone_number: props.addressData.telephone || props.addressData.phone_number || null,
  email: props.customerEmail,
  street: props.addressData.street,
  house_number: props.addressData.house_number,
  suffix: props.addressData.suffix,
  postcode: props.addressData.postcode,
  city: props.addressData.city,
  state: props.addressData.state,
  country: props.addressData.country,
});

const selectedCountry = computed(() => {
  return props.countries.find((country) => country.id === form.country);
});

const stateSelectOptions = computed(() => {
  return selectedCountry.value?.states.map((item) => {
    return {
      label: item.state,
      value: item.code,
    };
  }) || {};
});

watch(() => form.country, () => {
  form.state = null;
});

function submitForm() {
  form.post(route('customer.intent.address', props.returnOrderIntent), {
    preserveScroll: true,
  });
}

const buttonColor = useBrandColor();
</script>

<template>
  <AppLayout>
    <div class="max-w-3xl mx-auto">
      <div class="flex flex-col sm:flex-row sm:space-x-4 justify-between border-b border-slate-100 pb-6 mb-4">
        <Heading>
          {{ $t('customer.address:contact-information:title') }}
        </Heading>
      </div>

      <Form @submit="submitForm">
        <div class="space-y-10">
          <FormLayout>
            <div class="mb-6">
              <FormLayout
                :columns="1"
                :columns-sm="3"
              >
                <FormLayout>
                  <TextInput
                    v-model="form.name"
                    :label="$t('customer.address:contact-information:name:label')"
                    :error="form.errors.name"
                  />
                </FormLayout>
                <FormLayout>
                  <TextInput
                    v-model="form.phone_number"
                    :label="$t('customer.address:contact-information:phone:label')"
                    :error="form.errors.phone_number"
                  />
                </FormLayout>

                <FormLayout>
                  <TextInput
                    v-model="form.email"
                    :label="$t('customer.address:contact-information:email:label')"
                    :disabled="!returnOrderIntent.form.isEmailChangeAllowed"
                    :error="form.errors.email"
                  />
                </FormLayout>
              </FormLayout>
            </div>

            <FormLayout>
              <div class="-mt-1 mb-2">
                <Heading :element="HeadingElement.H2">
                  <TextStyle :font-size="TextStyleFontSize.Base">
                    {{ $t('customer.address:address:title') }}
                  </TextStyle>
                </Heading>
              </div>
            </FormLayout>

            <FormLayout :columns-sm="2">
              <FormLayout>
                <FormLayout>
                  <TextInput
                    v-model="form.street"
                    :label="$t('customer.address:address:street:label')"
                    :error="form.errors.street || form.errors.complete_address"
                  />
                </FormLayout>
              </FormLayout>

              <FormLayout :columns-sm="2">
                <FormLayout>
                  <TextInput
                    v-model="form.house_number"
                    :label="$t('customer.address:address:house_number:label')"
                    :error="form.errors.house_number || form.errors.house_number_suffix_combination"
                  />
                </FormLayout>

                <FormLayout>
                  <TextInput
                    v-model="form.suffix"
                    :label="$t('customer.address:address:suffix:label')"
                    :error="form.errors.suffix"
                  />
                </FormLayout>
              </FormLayout>
            </FormLayout>

            <FormLayout :columns-sm="2">
              <FormLayout :columns-sm="2">
                <FormLayout>
                  <TextInput
                    v-model="form.postcode"
                    :label="$t('customer.address:address:postcode:label')"
                    :error="form.errors.postcode"
                  />
                </FormLayout>

                <FormLayout>
                  <TextInput
                    v-model="form.city"
                    :label="$t('customer.address:address:city:label')"
                    :error="form.errors.city"
                  />
                </FormLayout>
              </FormLayout>

              <FormLayout>
                <FormLayout>
                  <Select
                    v-model="form.country"
                    :label="$t('customer.address:address:country:label')"
                    :options="countrySelectOptions"
                    :error="form.errors.country"
                  />
                </FormLayout>
              </FormLayout>
            </FormLayout>

            <FormLayout :columns-sm="2">
              <FormLayout>
                <FormLayout>
                  <Select
                    v-if="selectedCountry?.hasStates"
                    v-model="form.state"
                    :label="$t('customer.address:address:state:label')"
                    :options="stateSelectOptions"
                    :error="form.errors.state"
                  />
                </FormLayout>
              </FormLayout>
            </FormLayout>
          </formlayout>
        </div>

        <div class="mt-12 ml-auto">
          <ButtonGroup>
            <Button
              full-width
              :href="$route('customer.intent.display', returnOrderIntent)"
            >
              {{ $t('customer.address:back-button') }}
            </Button>
            <Button
              :color="buttonColor"
              full-width
              type="submit"
              :loading="form.processing"
            >
              {{ $t('customer.address:continue-button') }}
            </Button>
          </ButtonGroup>
        </div>
      </Form>
    </div>
  </AppLayout>
</template>
