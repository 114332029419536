<script lang="ts" setup>
import {
  Banner,
  BannerVariant,
  Button,
  ButtonGroup,
  Form,
  Heading,
  Listbox,
  ListboxOption,
} from '@app/customer/Components';
import { AppLayout } from '@app/customer/Layouts';
import { useForm } from '@inertiajs/vue3';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { type Types } from '@app/shared/types/generated-v2';
import { paymentMethodService } from '@app/customer/Services';

type ListPaymentMethodsViewModel = Types['App.Http.ViewModels.Customer.ListPaymentMethodsViewModel'];

const props = defineProps<ListPaymentMethodsViewModel>();

const buttonColor = useBrandColor();
const { getPaymentMethodLogo } = paymentMethodService();

const form = useForm({
  payment_method: props.selectedPaymentMethod?.id,
});

function submitForm() {
  form.post(route('customer.intent.payment-method.update', props.returnOrderIntent), {
    preserveScroll: true,
  });
}
</script>

<template>
  <AppLayout>
    <div class="max-w-3xl mx-auto">
      <div class="flex flex-col sm:flex-row sm:space-x-4 justify-between border-b border-slate-100 pb-6">
        <Heading>
          {{ $t('customer.payment:title') }}
        </Heading>
      </div>

      <div
        v-if="paymentStatus"
        class="mt-8 mb-8"
      >
        <Banner :variant="BannerVariant.Warning">
          {{ $t('customer.payment:unable-to-process') }}
        </Banner>
      </div>

      <div
        v-if="form.errors.payment_method"
        class="mb-5"
      >
        <Banner :variant="BannerVariant.Critical">
          {{ $t('customer.payment:is-required') }}
        </Banner>
      </div>

      <Form
        class="mt-4"
        @submit="submitForm"
      >
        <Listbox>
          <template
            v-for="paymentMethod in paymentMethods"
            :key="paymentMethod.id"
          >
            <ListboxOption
              v-model="form.payment_method"
              :label="paymentMethod.name"
              :value="paymentMethod.id"
              :image-url="getPaymentMethodLogo(paymentMethod)"
              :alt="paymentMethod.name"
            />
          </template>
        </Listbox>

        <div class="mt-12 ml-auto">
          <ButtonGroup>
            <Button
              full-width
              :href="$route('customer.intent.shipping-method', returnOrderIntent)"
            >
              {{ $t('customer.payment:back-button') }}
            </Button>

            <Button
              :color="buttonColor"
              full-width
              type="submit"
              name="submit_action"
              value="payment_method"
            >
              {{ $t('customer.payment:pay-button', { price: price }) }}
            </Button>
          </ButtonGroup>
        </div>
      </Form>
    </div>
  </AppLayout>
</template>
