import { ReturnOrderQuestion } from '@app/customer/types';
import { ConditionEvaluator, ConditionListParser } from '@app/shared/lib/ConditionEngine';
import * as Types from '@app/panel/types/generated';
import dayjs from 'dayjs';
import { map, values } from 'lodash';
import { ProductData } from '../DataTransferObjects/ProductData';
import { CustomerData } from '../DataTransferObjects/CustomerData';
import { SalesOrderItemData } from '../DataTransferObjects/SalesOrderItemData';
import { SalesOrderData } from '../DataTransferObjects/SalesOrderData';
import type { OrderForm } from './types';
import { Currency, Dimension } from '../ValueObjects';

type SalesOrderViewModel = Types.App.Http.ViewModels.Customer.SalesOrderViewModel;

function evaluateQuestion(question: ReturnOrderQuestion, input: object): boolean {
  const conditions = values(question.conditions);

  // If there are no conditions for this question, we can immediately return true.
  if (conditions.length === 0) {
    return true;
  }

  const conditionList = ConditionListParser.fromReturnQuestionConditions(conditions);
  const conditionEvaluator = new ConditionEvaluator(conditionList);

  return conditionEvaluator.evaluate(input);
}

function evaluateOrder(
  form: OrderForm,
  questions: ReturnOrderQuestion[],
  salesOrder: SalesOrderViewModel,
  requestData: object,
) {
  const result = questions.map((question: ReturnOrderQuestion) => {
    const salesOrderData = new SalesOrderData(
      salesOrder.currency,
      dayjs(salesOrder.orderDate.value).format('YYYY-MM-DD'),
      salesOrder.orderNumber,
      salesOrder.deliveryAge,
      salesOrder.paymentMethod,
      salesOrder.shippingMethod,
      salesOrder.status,
      salesOrder.metaData,
    );

    const customerData = new CustomerData(
      salesOrder.customer.email,
      salesOrder.customer.name,
      requestData?.address_data.country,
    );

    const attributes = {
      answers: form.answers,
      customer: customerData,
      sales_order: salesOrderData,
    };

    if (evaluateQuestion(question, attributes)) {
      return question;
    }
  });

  return result.filter(Boolean);
}

function evaluateOrderItems(
  form: OrderForm,
  questions: ReturnOrderQuestion[],
  salesOrder: SalesOrderViewModel,
  requestData: object,
) {
  const items = salesOrder.salesOrderItems
    .filter((salesOrderItem) => {
      return form.item[salesOrderItem.id].quantity > 0;
    });

  return items.reduce((acc, salesOrderItem) => {
    const result = questions.map((question) => {
      const formSalesOrderItem = form.item[salesOrderItem.id];

      const productData = new ProductData(
        salesOrderItem.product.barcode,
        salesOrderItem.product.hsCode,
        salesOrderItem.product.brand,
        Dimension.fromMillimeters(salesOrderItem.product.height).toCentimeters(),
        Dimension.fromMillimeters(salesOrderItem.product.length).toCentimeters(),
        Dimension.fromMillimeters(salesOrderItem.product.width).toCentimeters(),
        salesOrderItem.product.name,
        salesOrderItem.product.sku,
        salesOrderItem.product.supplier,
        map(salesOrderItem.product.categories, 'name'),
      );

      const customerData = new CustomerData(
        salesOrder.customer.email,
        salesOrder.customer.name,
        requestData?.address_data?.country,
      );

      const salesOrderItemData = new SalesOrderItemData(
        Currency.fromMinorValue(salesOrderItem.discount.amount).toMajorUnits(),
        salesOrderItem.quantity,
        Currency.fromMinorValue(salesOrderItem.price.amount).toMajorUnits(),
        salesOrderItem.metaData,
      );

      const salesOrderData = new SalesOrderData(
        salesOrder.currency,
        dayjs(salesOrder.orderDate.value).format('YYYY-MM-DD'),
        salesOrder.orderNumber,
        salesOrder.deliveryAge,
        salesOrder.paymentMethod,
        salesOrder.shippingMethod,
        salesOrder.status,
        salesOrder.metaData,
      );

      const attributes = {
        answers: formSalesOrderItem.answers,
        quantity: formSalesOrderItem.quantity,
        return_reason: formSalesOrderItem.return_reason,
        sales_order: salesOrderData,
        sales_order_item: salesOrderItemData,
        customer: customerData,
        product: productData,
      };

      if (evaluateQuestion(question, attributes)) {
        return question;
      }
    });

    acc[salesOrderItem.id] = result.filter(Boolean);

    return acc;
  }, {});
}

export function evaluateReturnQuestions(
  form: OrderForm,
  questions: ReturnOrderQuestion[],
  salesOrder: SalesOrderViewModel,
  requestData: object,
) {
  const orderQuestions = questions
    .filter((question: ReturnOrderQuestion): boolean => {
      return question.level === 'order';
    });

  const orderItemQuestions = questions
    .filter((question) => {
      return question.level === 'order-item';
    });

  return {
    order: evaluateOrder(form, orderQuestions, salesOrder, requestData),
    orderItems: evaluateOrderItems(form, orderItemQuestions, salesOrder, requestData),
  };
}
