import { round } from 'lodash';

export default class Currency {
  private readonly value: number;

  private constructor(value: number) {
    if (isNaN(value)) {
      throw Error('The currency value cannot be converted to a number.');
    }

    if (value < 0) {
      throw Error('The currency value cannot be a negative number.');
    }

    this.value = value;
  }

  public toMinorUnits(): number {
    return this.value;
  }

  public toMajorUnits(): number {
    return round(this.value / 100, 2);
  }

  public static fromMinorValue(minorValue: number): Currency {
    const numericValue = Number(minorValue);

    return new Currency(numericValue);
  }

  public static fromMajorValue(majorValue: number): Currency {
    const numericValue = Number(majorValue);

    return new Currency(numericValue * 100);
  }
}
