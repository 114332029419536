import { computed, ComputedRef, ref, watch } from 'vue';
import { SalesOrderItem } from '@app/customer/types';
import type { Types } from '@app/shared/types/generated-v2';

type SalesOrderViewModel = Types['App.Http.ViewModels.Customer.SalesOrderViewModel'];

export function useFilter(
  salesOrder: ComputedRef<SalesOrderViewModel | null>,
  form: any,
  isVirtualIntegration: ComputedRef<boolean>,
) {
  const AMOUNT_OF_RESULTS = 12;

  const phrase = ref('');
  const showSelected = ref(false);

  function filter(filterPhrase: string) {
    phrase.value = filterPhrase;
    showSelected.value = false;
  }

  function filterSelected(newShowSelected: boolean) {
    phrase.value = '';
    showSelected.value = newShowSelected;
  }

  const salesOrderItems = computed(() => {
    if (showSelected.value) {
      return selectedSalesOrderItems.value;
    }

    const items = salesOrder.value?.salesOrderItems ?? [];

    if (phrase.value === '') {
      return items;
    }

    return items
      .filter(showItem)
      .slice(0, AMOUNT_OF_RESULTS);
  });

  const shouldShowFilter = computed(() => {
    // Never show filter for virtual integrations
    if (isVirtualIntegration.value) {
      return false;
    }

    if (salesOrder.value?.salesOrderItems.length <= AMOUNT_OF_RESULTS) {
      return false;
    }

    return salesOrderItems.value?.length || phrase.value !== '';
  });

  const selectedSalesOrderItems = computed(() => {
    if (!form.item) {
      return [];
    }

    return salesOrder.value?.salesOrderItems.filter((item) => {
      if (form.item[item.id] === undefined) {
        return false;
      }

      return form.item[item.id].quantity > 0;
    }) || [];
  });

  watch(selectedSalesOrderItems, () => {
    // Disable showing selected items, when there are no selected items to show
    if (selectedSalesOrderItems.value.length === 0 && showSelected.value) {
      showSelected.value = false;
    }
  });

  function showItem(salesOrderItem: SalesOrderItem) {
    if (salesOrder.value?.salesOrderItems.length <= AMOUNT_OF_RESULTS) {
      return true;
    }

    if (phrase.value === '') {
      return true;
    }

    return salesOrderItem.product.name.toLowerCase().includes(phrase.value.toLowerCase())
      || salesOrderItem.product.sku?.toLowerCase().includes(phrase.value.toLowerCase());
  }

  return {
    phrase,
    showSelected,
    filter,
    filterSelected,
    salesOrderItems,
    shouldShowFilter,
    selectedSalesOrderItems,
  };
}
