<script lang="ts" setup>
import { Button, Form, Textarea } from '@app/customer/Components';
import { useForm } from '@inertiajs/vue3';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import NoteAttachment from '@app/customer/Containers/Notes/components/NoteAttachment.vue';
import { PaperClipIcon } from '@heroicons/vue/24/outline';
import { inject } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';

const returnOrderIntent = inject<Types['App.Models.ViewModels.Customer.ReturnOrderIntentViewModel']>('returnOrderIntent');

const props = defineProps<{
  returnOrderItem?: Types['App.Models.ViewModels.Customer.ReturnOrderItemViewModel'];
}>();

const form = useForm({
  note: '',
  attachments: [],
});

function createNote() {
  form.post(
    route('customer.intent.notes.create', {
      returnOrderIntent: returnOrderIntent.id,
      returnOrderItemId: props.returnOrderItem?.id ?? null,
    }),
    {
      preserveScroll: true,
      onSuccess: () => {
        form.reset();
      },
    },
  );
}

const buttonColor = useBrandColor();

function attachFiles(attachments): void {
  form.attachments = attachments;
}
</script>

<template>
  <Form
    class="space-y-4"
    @submit="createNote"
  >
    <Textarea
      v-model="form.note"
      :error="form.errors.note"
      :placeholder="$t('customer.status:notes:comment-placeholder')"
      required
    />

    <div
      v-if="form.attachments.length > 0"
      class="flex flex-wrap gap-2"
    >
      <template
        v-for="attachment in form.attachments"
        :key="attachment.id"
      >
        <div class="rounded-md px-2 py-1.5 bg-blue-50 hover:bg-blue-100 cursor-pointer w-fit">
          <a
            class="flex items-center space-x-1.5"
            :href="attachment.previewUrl"
            target="_blank"
          >
            <PaperClipIcon class="h-4 w-4 text-slate-500" />

            <span class="text-xs text-slate-700">{{ attachment.filename }}</span>
          </a>
        </div>
      </template>
    </div>

    <div class="flex space-x-3 justify-start items-center">
      <Button
        :color="buttonColor"
        :loading="form.processing"
        type="submit"
      >
        {{ $t('customer.status:notes:comment-button') }}
      </Button>

      <NoteAttachment
        :attachments="form.attachments"
        @attach="attachFiles"
      />
    </div>
  </Form>
</template>
