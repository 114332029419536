<script lang="ts" setup>
import {
  Badge,
  ErrorMessage,
  HelpText,
  InputLabel,
  type SelectOption,
} from '@app/customer/Components';
import { useUniqueId } from '@app/customer/Composables/useUniqueId';
import { computed, ref, watch } from 'vue';

const props = withDefaults(
  defineProps<{
    modelValue?: string[];
    options: SelectOption[];
    id?: string;
    label: string;
    labelFor?: string;
    labelHidden?: boolean;
    error?: string;
  }>(), {
    id: null,
    modelValue: null,
    error: null,
    labelFor: null,
  },
);

const selectedOptions = computed(() => {
  return props.modelValue
    ? props.modelValue.filter(Boolean)
    : [];
});

const emit = defineEmits(['change', 'update:modelValue']);

const unselectedOptions = computed(() => {
  return props.options.filter((option) => {
    return !selectedOptions.value.includes(option.value as string);
  });
});

const elementId = computed((): string => props.id || useUniqueId('select'));

const onMultiSelectChange = (e: InputEvent): void => {
  const targetValue = (e.target as HTMLInputElement).value as string;

  const newModelValue = [...selectedOptions.value];

  if (!newModelValue.includes(targetValue)) {
    newModelValue.push(targetValue);
  } else {
    newModelValue.splice(newModelValue.indexOf(targetValue), 1);
  }

  (e.target as HTMLInputElement).value = null;

  emit('update:modelValue', newModelValue);
  emit('change', e);
};

function unselect(value: string) {
  const newModelValue = [...selectedOptions.value];
  newModelValue.splice(newModelValue.indexOf(value), 1);

  emit('update:modelValue', newModelValue);
}

function getLabel(value: string): string {
  const option = props.options.find((option: SelectOption) => {
    return option.value === value;
  });

  return option.label;
}

const multiSelect = ref<HTMLSelectElement | null>(null);

watch(() => props.options, () => {
  if (multiSelect.value) {
    multiSelect.value.value = '';
  }
});
</script>

<template>
  <div class="relative text-sm">
    <InputLabel
      v-if="label"
      :label="label"
      :label-for="elementId"
      :label-hidden="labelHidden"
      :flush="!! $slots['help-text']"
    />

    <HelpText v-if="!! $slots['help-text']">
      <slot name="help-text" />
    </HelpText>

    <select
      :id="elementId"
      ref="multiSelect"
      class="border-slate-300 focus:ring-black text-sm relative pr-10 bg-white focus:outline-none focus:ring-2 block w-full rounded-md border px-3 py-2 shadow-sm cursor-pointer select-none font-normal appearance-none text-slate-400"
      @change="onMultiSelectChange"
    >
      <option
        value=""
        selected
        class="hidden"
      >
        {{ $t('panel.global:select:empty-option-label') }}
      </option>

      <option
        v-for="(option, index) in unselectedOptions"
        :key="index"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </select>

    <div
      v-if="selectedOptions && selectedOptions.length > 0"
      class="my-2 flex gap-2 flex-wrap"
    >
      <Badge
        v-for="(value, index) in selectedOptions"
        :key="index"
        closeable
        color="slate"
        @close="unselect(value)"
      >
        {{ getLabel(value) }}
      </Badge>
    </div>

    <ErrorMessage
      v-if="error"
      :message="error"
    />
  </div>
</template>
