import { ToastVariant } from '@app/customer/Components';
import { useToast } from '@app/customer/Composables/useToast';
import { usePanel } from '@app/panel/Composables/useHttp';
import { AxiosError } from 'axios';
import { readonly, ref } from 'vue';
import { type Types } from '@app/shared/types/generated-v2';
import { isEmpty } from 'lodash';

type ShipmentViewModel = Types['App.Models.ViewModels.Customer.ShipmentViewModel'];

export function useShipmentLabel() {
  const loading = ref<boolean>(false);
  const svg = ref(null);

  const { emitToastEvent } = useToast();

  async function getLabel(shipment: ShipmentViewModel) {
    if (!shipment.isPrinterless || isEmpty(shipment.trackingNumber)) {
      return;
    }

    loading.value = true;

    await usePanel().get(route('panel.shipments.label', shipment))
      .then((response) => {
        svg.value = response.data;
      })
      .catch((error: AxiosError) => {
        emitToastEvent({
          title: error.name,
          message: error.message,
          variant: ToastVariant.Critical,
        });
      })
      .finally(() => {
        loading.value = false;
      });
  }

  return {
    getLabel,
    loading: readonly(loading),
    svg: readonly(svg),
  };
}
