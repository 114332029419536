<template>
  <svg
    id="Laag_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 312.16 48.64"
    style="enable-background:new 0 0 312.16 48.64;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M0,47.61V19.46c0-0.08,0.06-0.14,0.14-0.14h8.24c0.08,0,0.14,0.06,0.14,0.14v3.49c0,0.14,0.17,0.19,0.25,0.08
			c1.01-1.41,2.21-2.52,3.62-3.31c1.53-0.86,3.17-1.29,4.93-1.29c1,0,2.04,0.15,3.11,0.44c1.02,0.28,1.88,0.64,2.58,1.07
			c0.06,0.04,0.08,0.12,0.05,0.18l-3.43,6.93c-0.04,0.07-0.13,0.1-0.2,0.05c-0.6-0.39-1.29-0.7-2.08-0.93
			c-0.84-0.25-1.62-0.37-2.35-0.37c-1.11,0-2.17,0.29-3.16,0.86c-1,0.57-1.8,1.32-2.42,2.23c-0.61,0.92-0.92,1.9-0.92,2.96v15.74
			c0,0.08-0.06,0.14-0.14,0.14H0.14C0.06,47.75,0,47.69,0,47.61z"
        />
        <path
          d="M40.62,48.64c-3.07,0-5.83-0.66-8.26-1.98c-2.43-1.32-4.36-3.12-5.79-5.4c-1.43-2.28-2.14-4.85-2.14-7.72
			c0-2.11,0.4-4.08,1.21-5.91c0.81-1.83,1.93-3.44,3.37-4.82c1.44-1.38,3.12-2.45,5.03-3.22c1.91-0.77,3.97-1.15,6.19-1.15
			c2.96,0,5.56,0.62,7.79,1.85c2.23,1.23,3.97,2.94,5.22,5.12c1.25,2.18,1.87,4.73,1.87,7.63c0,0.24,0,0.45-0.01,0.62
			c-0.01,0.16-0.04,0.45-0.08,0.86c-0.01,0.07-0.07,0.13-0.14,0.13H33.35c-0.08,0-0.14,0.07-0.14,0.15
			c0.02,1.38,0.35,2.59,0.98,3.63c0.66,1.07,1.58,1.91,2.76,2.51c1.18,0.6,2.56,0.9,4.14,0.9c1.48,0,2.79-0.29,3.93-0.86
			c1.11-0.56,2.19-1.45,3.22-2.66c0.04-0.05,0.11-0.06,0.17-0.04l5.9,2.96c0.07,0.04,0.1,0.13,0.06,0.2
			c-1.33,2.23-3.19,3.98-5.59,5.25C46.33,47.99,43.61,48.64,40.62,48.64z M33.67,29.93h12.88c0.09,0,0.16-0.08,0.14-0.17
			c-0.2-1.02-0.58-1.91-1.15-2.66c-0.6-0.8-1.37-1.41-2.29-1.83c-0.93-0.43-1.97-0.64-3.14-0.64c-1.24,0-2.33,0.21-3.28,0.64
			c-0.94,0.43-1.7,1.04-2.28,1.83c-0.55,0.76-0.89,1.65-1.03,2.67C33.52,29.85,33.59,29.93,33.67,29.93z"
        />
        <path
          d="M71.79,48.64c-2.83,0-5.03-0.9-6.61-2.71c-1.57-1.8-2.36-4.33-2.36-7.57V25.93c0-0.08-0.06-0.14-0.14-0.14
			h-3.52c-0.08,0-0.14-0.06-0.14-0.14v-6.19c0-0.08,0.06-0.14,0.14-0.14h3.52c0.08,0,0.14-0.06,0.14-0.14V8.8
			c0-0.08,0.06-0.14,0.14-0.14h8.24c0.08,0,0.14,0.06,0.14,0.14v10.38c0,0.08,0.06,0.14,0.14,0.14h5.97c0.08,0,0.14,0.06,0.14,0.14
			v6.19c0,0.08-0.06,0.14-0.14,0.14h-5.97c-0.08,0-0.14,0.06-0.14,0.14v11.82c0,1.07,0.22,1.89,0.65,2.44
			c0.43,0.56,1.05,0.83,1.85,0.83c0.83,0,1.63-0.26,2.4-0.78c0.08-0.05,0.18-0.02,0.21,0.07l2.05,6.54
			c0.02,0.07-0.01,0.14-0.07,0.17c-0.94,0.49-1.98,0.88-3.13,1.17C74.11,48.49,72.94,48.64,71.79,48.64z"
        />
        <path
          class="st0"
          d="M99.28,48.64c-5.18,0-8.92-1.03-11.22-3.08c-2.3-2.05-3.44-5.41-3.44-10.08V19.46c0-0.08,0.06-0.14,0.14-0.14
			H93c0.08,0,0.14,0.06,0.14,0.14v14.02c0,3,0.47,5.12,1.4,6.36c0.93,1.24,2.51,1.86,4.73,1.86c2.26,0,3.84-0.62,4.76-1.86
			c0.92-1.24,1.37-3.36,1.37-6.36V19.46c0-0.08,0.06-0.14,0.14-0.14h8.24c0.08,0,0.14,0.06,0.14,0.14v16.02
			c0,4.66-1.14,8.02-3.43,10.08C108.22,47.61,104.48,48.64,99.28,48.64z"
        />
        <path
          d="M122.74,47.61V19.46c0-0.08,0.06-0.14,0.14-0.14h8.24c0.08,0,0.14,0.06,0.14,0.14v3.49
			c0,0.14,0.17,0.19,0.25,0.08c1.01-1.41,2.21-2.52,3.62-3.31c1.53-0.86,3.17-1.29,4.93-1.29c1,0,2.04,0.15,3.11,0.44
			c1.02,0.28,1.88,0.64,2.58,1.07c0.06,0.04,0.08,0.12,0.05,0.18l-3.43,6.93c-0.04,0.07-0.13,0.1-0.2,0.05
			c-0.6-0.39-1.29-0.7-2.08-0.93c-0.84-0.25-1.62-0.37-2.35-0.37c-1.11,0-2.17,0.29-3.16,0.86c-1,0.57-1.8,1.32-2.42,2.23
			c-0.61,0.92-0.92,1.9-0.92,2.96v15.74c0,0.08-0.06,0.14-0.14,0.14h-8.24C122.8,47.75,122.74,47.69,122.74,47.61z"
        />
        <path
          d="M150.02,47.4V19.67c0-0.19,0.16-0.35,0.35-0.35h7.82c0.19,0,0.35,0.16,0.35,0.35v1.95
			c0,0.31,0.37,0.47,0.59,0.25c0.98-0.94,2.15-1.72,3.51-2.33c1.64-0.74,3.45-1.11,5.43-1.11c2.18,0,4.1,0.48,5.76,1.43
			c1.66,0.95,2.96,2.28,3.9,3.97c0.94,1.69,1.42,3.67,1.42,5.93V47.4c0,0.19-0.16,0.35-0.35,0.35h-7.82c-0.19,0-0.35-0.16-0.35-0.35
			V31.57c0-2.04-0.53-3.62-1.6-4.76c-1.06-1.14-2.52-1.71-4.37-1.71c-1.26,0-2.35,0.25-3.26,0.76c-0.92,0.51-1.62,1.2-2.11,2.08
			c-0.49,0.88-0.74,1.87-0.74,2.98V47.4c0,0.19-0.16,0.35-0.35,0.35h-7.82C150.18,47.75,150.02,47.59,150.02,47.4z"
        />
        <path
          d="M188.06,47.61V0.14c0-0.08,0.06-0.14,0.14-0.14h8.24c0.08,0,0.14,0.06,0.14,0.14v47.47
			c0,0.08-0.06,0.14-0.14,0.14h-8.24C188.12,47.75,188.06,47.69,188.06,47.61z"
        />
        <path
          d="M219.71,48.64c-3.07,0-5.83-0.66-8.26-1.98c-2.43-1.32-4.36-3.12-5.79-5.4c-1.43-2.28-2.14-4.85-2.14-7.72
			c0-2.11,0.4-4.08,1.21-5.91c0.81-1.83,1.93-3.44,3.37-4.82c1.44-1.38,3.12-2.45,5.02-3.22c1.91-0.77,3.97-1.15,6.19-1.15
			c2.96,0,5.56,0.62,7.79,1.85c2.23,1.23,3.97,2.94,5.22,5.12c1.25,2.18,1.87,4.73,1.87,7.63c0,0.24,0,0.45-0.01,0.62
			c-0.01,0.16-0.04,0.45-0.08,0.86c-0.01,0.07-0.07,0.13-0.14,0.13h-21.52c-0.08,0-0.14,0.07-0.14,0.15
			c0.02,1.38,0.35,2.59,0.98,3.63c0.66,1.07,1.58,1.91,2.76,2.51c1.18,0.6,2.56,0.9,4.14,0.9c1.48,0,2.79-0.29,3.93-0.86
			c1.11-0.56,2.19-1.45,3.22-2.66c0.04-0.05,0.11-0.06,0.17-0.04l5.9,2.96c0.07,0.04,0.1,0.13,0.06,0.2
			c-1.33,2.23-3.19,3.98-5.59,5.25C225.43,47.99,222.71,48.64,219.71,48.64z M212.76,29.93h12.88c0.09,0,0.16-0.08,0.14-0.17
			c-0.2-1.02-0.58-1.91-1.15-2.66c-0.6-0.8-1.37-1.41-2.29-1.83c-0.93-0.43-1.97-0.64-3.14-0.64c-1.24,0-2.33,0.21-3.28,0.64
			c-0.94,0.43-1.7,1.04-2.28,1.83c-0.55,0.76-0.89,1.65-1.03,2.67C212.61,29.85,212.68,29.93,212.76,29.93z"
        />
        <path
          d="M249.19,48.64c-2.41,0-4.56-0.38-6.45-1.14c-1.86-0.74-3.42-1.83-4.66-3.25c-0.04-0.05-0.05-0.12-0.01-0.17
			l3.43-5.48c0.05-0.08,0.16-0.09,0.22-0.02c1.15,1.19,2.35,2.11,3.6,2.76c1.3,0.67,2.59,1,3.89,1c0.72,0,1.35-0.12,1.87-0.36
			c0.53-0.24,0.94-0.59,1.25-1.04c0.31-0.45,0.46-1,0.46-1.65c0-0.74-0.21-1.32-0.62-1.75c-0.42-0.43-1.05-0.82-1.9-1.18
			c-0.85-0.36-1.95-0.82-3.3-1.37c-1.15-0.48-2.25-1.03-3.3-1.64c-1.05-0.61-1.91-1.39-2.57-2.33c-0.66-0.94-0.99-2.14-0.99-3.58
			c0-1.76,0.49-3.31,1.47-4.65c0.98-1.34,2.34-2.39,4.07-3.15c1.73-0.76,3.72-1.14,5.98-1.14c1.41,0,2.88,0.21,4.43,0.64
			c1.51,0.42,3.11,1.04,4.82,1.88c0.07,0.04,0.1,0.12,0.06,0.19l-2.78,5.21c-0.04,0.07-0.13,0.09-0.2,0.05
			c-2.34-1.48-4.43-2.22-6.28-2.22c-1.05,0-1.9,0.23-2.53,0.69c-0.63,0.46-0.94,1.05-0.94,1.75c0,0.67,0.33,1.24,0.99,1.74
			s1.46,0.93,2.42,1.3c0.95,0.38,1.86,0.73,2.73,1.04c1.42,0.52,2.69,1.09,3.79,1.72c1.1,0.63,1.97,1.45,2.6,2.46
			c0.63,1.01,0.94,2.36,0.94,4.04c0,2.05-0.5,3.8-1.48,5.23c-0.99,1.43-2.42,2.53-4.28,3.29C254.03,48.26,251.8,48.64,249.19,48.64z
			"
        />
        <path
          d="M277.06,48.64c-2.41,0-4.56-0.38-6.45-1.14c-1.86-0.74-3.42-1.83-4.66-3.25c-0.04-0.05-0.05-0.12-0.01-0.17
			l3.43-5.48c0.05-0.08,0.16-0.09,0.22-0.02c1.15,1.19,2.35,2.11,3.6,2.76c1.3,0.67,2.59,1,3.89,1c0.72,0,1.35-0.12,1.87-0.36
			c0.53-0.24,0.94-0.59,1.25-1.04c0.31-0.45,0.46-1,0.46-1.65c0-0.74-0.21-1.32-0.62-1.75c-0.42-0.43-1.05-0.82-1.9-1.18
			c-0.85-0.36-1.95-0.82-3.3-1.37c-1.15-0.48-2.25-1.03-3.3-1.64c-1.05-0.61-1.91-1.39-2.57-2.33c-0.66-0.94-0.99-2.14-0.99-3.58
			c0-1.76,0.49-3.31,1.47-4.65c0.98-1.34,2.34-2.39,4.07-3.15c1.73-0.76,3.72-1.14,5.98-1.14c1.41,0,2.88,0.21,4.43,0.64
			c1.51,0.42,3.11,1.04,4.82,1.88c0.07,0.04,0.1,0.12,0.06,0.19l-2.78,5.21c-0.04,0.07-0.13,0.09-0.2,0.05
			c-2.34-1.48-4.43-2.22-6.28-2.22c-1.05,0-1.9,0.23-2.53,0.69c-0.63,0.46-0.94,1.05-0.94,1.75c0,0.67,0.33,1.24,0.99,1.74
			c0.66,0.49,1.46,0.93,2.42,1.3c0.95,0.38,1.86,0.73,2.73,1.04c1.42,0.52,2.69,1.09,3.79,1.72c1.1,0.63,1.97,1.45,2.6,2.46
			c0.63,1.01,0.94,2.36,0.94,4.04c0,2.05-0.5,3.8-1.48,5.23c-0.99,1.43-2.42,2.53-4.28,3.29C281.91,48.26,279.67,48.64,277.06,48.64
			z"
        />
      </g>
      <g>
        <g>
          <path
            d="M298.59,47.73l13.12,0c0.18,0,0.32-0.15,0.32-0.34l0-8.74c0-0.19-0.14-0.34-0.32-0.34l-13.12,0
				c-0.18,0-0.32,0.15-0.32,0.34v8.74C298.27,47.57,298.41,47.73,298.59,47.73z"
          />
          <path
            d="M298.27,34.32v2.29c0,0.2,0.14,0.36,0.32,0.36l13.12,0c0.18,0,0.32-0.16,0.32-0.36v-2.29
				c0-0.2-0.14-0.36-0.32-0.36l-13.12,0C298.41,33.96,298.27,34.12,298.27,34.32z"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
