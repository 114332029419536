<script lang="ts" setup>
import {
  computed,
  ref,
} from 'vue';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { useBrandColor } from '../../../Composables/useBrandColor';

const props = defineProps({
  id: {
    type: String,
    default: null,
  },

  name: {
    type: String,
    default: null,
  },

  value: {
    type: [String, null],
    default: null,
  },

  title: {
    type: String,
    default: null,
  },

  subtitle: {
    type: String,
    default: null,
  },

  modelValue: {
    type: [Array, Boolean],
    default: false,
  },
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: boolean | unknown[]): void;
}>();

const toggleColor = useBrandColor().value.background;

const elementId = computed(() => props.id || useUniqueId('searchField'));

const checked = computed(() => {
  if (props.modelValue instanceof Array) {
    return props.modelValue.includes(props.value);
  }

  return props.modelValue;
});

const onToggleChange = (e: InputEvent): void => {
  const target = e.target as HTMLInputElement;

  if (typeof props.modelValue === 'boolean') {
    return emit('update:modelValue', target.checked);
  }

  const value = target.value;
  const newModelValue = [...props.modelValue];

  if (target.checked) {
    newModelValue.push(value);
  } else {
    newModelValue.splice(newModelValue.indexOf(value), 1);
  }

  emit('update:modelValue', newModelValue);
};

const toggleInput = ref<HTMLInputElement>(null);

function activateToggle() {
  toggleInput.value.click();
}
</script>

<template>
  <label
    data-test="toggle"
    :for="elementId"
    class="flex w-fit items-center cursor-pointer space-x-2"
  >
    <span
      class="relative focus:outline-blue-300"
      tabindex="0"
      @keyup.enter="activateToggle"
    >

      <input
        :id="elementId"
        ref="toggleInput"
        type="checkbox"
        class="sr-only"
        :name="name"
        :value="value"
        :checked="checked"
        @change="onToggleChange"
      >

      <span
        class="block w-10 h-6 rounded-full bg-slate-200"
        :style="checked ? `background-color: ${toggleColor}` : ''"
      />

      <span
        class="absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition"
        :class="checked ? 'translate-x-full' : ''"
      />
    </span>

    <span :class="{'flex flex-col': subtitle}">
      <span
        v-if="title"
        data-test="toggle-title"
        class="text-sm text-slate-900"
      >
        {{ title }}
      </span>

      <span
        v-if="subtitle"
        data-test="toggle-subtitle"
        class="block text-xs text-slate-500"
      >
        {{ subtitle }}
      </span>
    </span>
  </label>
</template>
