<script lang="ts" setup>
import { Button } from '@app/customer/Components';
import { type Types } from '@app/shared/types/generated-v2';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { router } from '@inertiajs/vue3';

type ReturnOrderIntentViewModel = Types['App.Models.ViewModels.Customer.ReturnOrderIntentViewModel'];

const props = defineProps<{
  returnOrderIntent: ReturnOrderIntentViewModel;
}>();

function completeShipment(): void {
  router.get(route('customer.intent.shipping-method', props.returnOrderIntent));
}

const brandColor = useBrandColor();
</script>

<template>
  <button
    class="group py-20 rounded-lg border border-dashed overflow-hidden h-full w-full shadow-sm cursor-pointer outline-none border-slate-300 hover:border-slate-400"
    @click="completeShipment"
  >
    <div class="h-full w-full flex flex-col justify-center items-center">
      <span class="text-2xl font-bold text-slate-900 mb-2">{{ $t('customer.status:shipment:no-shipments') }}</span>
      <Button :color="brandColor">
        {{ $t('customer.status:shipment:create-shipment') }}
      </Button>
    </div>
  </button>
</template>
