import axios from 'axios';

export function download(url: string, filename: string): void {
  axios({
    url,
    method: 'GET',
    responseType: 'blob',
  }).then((response) => {
    const fileURL = window.URL.createObjectURL(new Blob([response.data]));
    const fileLink = document.createElement('a');

    fileLink.href = fileURL;
    fileLink.setAttribute('download', filename);
    document.body.appendChild(fileLink);

    fileLink.click();
    document.body.removeChild(fileLink);
  });
}
