<script setup lang="ts">
const AMOUNT_OF_LOADING_LINES = 3;
</script>

<template>
  <div
    v-cloak
    class="mb-10"
  >
    <div
      v-for="index in AMOUNT_OF_LOADING_LINES"
      :key="index"
      class="flex w-full space-x-8 py-8 border-t border-slate-100"
    >
      <div class="w-36 h-36 animate-pulse rounded-lg bg-slate-200" />
      <div class="flex-grow space-y-4">
        <div class="h-6 animate-pulse rounded-lg bg-slate-200" />
        <div class="h-6 animate-pulse rounded-lg bg-slate-200" />
      </div>
      <div class="w-40 h-24 animate-pulse rounded-lg bg-slate-200" />
    </div>
  </div>
</template>
