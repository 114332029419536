<script lang="ts" setup>
import {
  computed,
  ref,
} from 'vue';
import { isEqual } from 'lodash';

const props = defineProps({
  name: {
    type: String,
    default: null,
  },

  value: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    default: null,
  },

  modelValue: {
    type: [Array, Boolean],
    default: false,
  },

  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);

const checked = computed(() => {
  if (props.modelValue instanceof Array) {
    if (typeof props.value === 'string') {
      return props.modelValue.includes(props.value);
    }
    return props.modelValue.some((item) => isEqual(item, props.value));
  }

  return props.modelValue;
});

const onCheckboxChange = (e: InputEvent): void => {
  const target = e.target as HTMLInputElement;

  if (typeof props.modelValue === 'boolean') {
    return emit('update:modelValue', target.checked);
  }

  const newModelValue = [...props.modelValue];

  if (target.checked) {
    newModelValue.push(props.value);
  } else {
    newModelValue.splice(newModelValue.indexOf(props.value), 1);
  }

  emit('update:modelValue', newModelValue);
};

const checkboxInput = ref<HTMLInputElement>(null);
function activateCheckboxInput() {
  checkboxInput.value.click();
}
</script>

<template>
  <label
    class="flex items-center cursor-pointer w-fit"
    :for="name"
    @keyup.enter="activateCheckboxInput"
  >
    <input
      :id="name"
      ref="checkboxInput"
      type="checkbox"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="disabled"
      class="h-4 w-4 border-slate-300 rounded cursor-pointer accent-blue-500"
      @change="onCheckboxChange"
    >

    <span class="block pl-2 text-sm text-slate-700 tracking-normal">
      {{ label }}
    </span>
  </label>
</template>
