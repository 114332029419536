<script lang="ts" setup>
import {
  ActionList,
  ActionListItem,
  Badge,
  Button,
  ButtonGroup,
  DescriptionItem,
  DescriptionList,
  Heading,
  HeadingSize,
  Link,
  Popover,
  PopoverPlacement,
  ResourceItem,
  ResourceList,
  TextStyle,
  TextStyleShade,
} from '@app/customer/Components';
import { useShipmentLabel } from '@app/customer/Composables/useShipment';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { useToggle } from '@app/customer/Composables/useToggle';
import { OrderStatusAnswerList, OrderStatusReturnItem, OrderStatusShipmentForm } from '@app/customer/Partials';
import { beaconService } from '@app/customer/Services';
import { AddressData } from '@app/customer/types';
import { App, type Types } from '@app/shared/types/generated-v2';
import { ArrowDownTrayIcon, EllipsisVerticalIcon } from '@heroicons/vue/24/outline';
import { isEmpty } from 'lodash';
import { computed, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import TextContainer from '@app/customer/Components/TextContainer/components/TextContainer.vue';

type ProductDetailType = App.Enums.ProductDetailType;
type ShipmentViewModel = Types['App.Models.ViewModels.Customer.ShipmentViewModel'];
type AnswersViewModel = Types['App.Models.ViewModels.Customer.ReturnQuestionAnswerViewModel'];
type ReturnOrderIntentViewModel = Types['App.Models.ViewModels.ReturnOrderIntentViewModel'];

const props = defineProps<{
  returnOrderIntent: ReturnOrderIntentViewModel;
  addressData: AddressData;
  countryName: string;
  shipment: ShipmentViewModel;
  visibleProductDetails: ProductDetailType[];
  answers: AnswersViewModel[];
  isSupportBeaconEnabled: boolean;
  replyToAddress: string | null;
}>();

const isSuccessful = computed<boolean>(() => !isEmpty(props.shipment.trackingNumber));

const isManualShipment = computed<boolean>(() => props.shipment.shippingMethod.name === 'Manual Shipment');

const needsPrinter = computed<boolean>(() => !props.shipment.isPrinterless);

const { helpscoutId, toggle } = beaconService();

function handleSupport(): void {
  if (props.isSupportBeaconEnabled) {
    toggle();
  }

  if (!props.isSupportBeaconEnabled) {
    window.location.href = `mailto:${props.replyToAddress}`;
  }
}

function openLabel(): void {
  window.open(route('panel.shipments.label', props.shipment), '_blank');
}

const { t } = useI18n();

const popoverItems = computed<ActionListItem[]>(() => {
  return [
    { content: t('customer.status:shipment:support'), onClick: () => toggle() },
  ];
});

const { deactivate: closePopover, toggle: togglePopover, value: popoverOpen } = useToggle();

const { svg, getLabel } = useShipmentLabel();

const brandColor = useBrandColor();

const showOpenLabelButton = computed(() => {
  if (isManualShipment.value) {
    return false;
  }

  if (props.shipment.shippingMethod.type === App.Enums.ShippingMethodType.ReturnInStore) {
    return false;
  }

  return isSuccessful.value && needsPrinter.value && props.shipment.showOpenLabelButton;
});

const showCarrier = computed<boolean>((): boolean => props.shipment.shippingMethod.type !== App.Enums.ShippingMethodType.ReturnInStore && props.shipment.shippingMethod.carrier);
const showReturnInStore = computed<boolean>((): boolean => props.shipment.shippingMethod.type === App.Enums.ShippingMethodType.ReturnInStore);

watch(() => props.shipment, (newShipment) => getLabel(newShipment), { immediate: true });
</script>

<template>
  <div>
    <ResourceList>
      <ResourceItem>
        <div class="md:hidden mb-5">
          <Button
            v-if="showOpenLabelButton"
            full-width
            :color="brandColor"
            @click="openLabel"
          >
            {{ $t('customer.status:shipment:open-label') }}
          </Button>
        </div>
        <div class="flex justify-between space-x-6">
          <DescriptionList>
            <DescriptionItem
              v-if="!isEmpty(shipment.trackingNumber)"
              :term="$t('customer.status:shipment:tracking-number')"
            >
              <div class="flex items-center mt-1">
                <img
                  v-if="shipment.logo"
                  class="w-4 mr-1"
                  :src="shipment.logo.imageSourceUrl"
                  :alt="shipment.logo.name"
                  loading="lazy"
                >
                <span v-if="! shipment.trackingUrl">
                  {{ shipment.trackingNumber }}
                </span>

                <Link
                  v-if="shipment.trackingUrl"
                  native
                  target="_blank"
                  :href="shipment.trackingUrl"
                >
                  {{ shipment.trackingNumber }}
                </Link>
              </div>
            </DescriptionItem>
            <DescriptionItem
              v-if="showCarrier"
              :term="$t('customer.status:shipment:shipping-method')"
            >
              {{ shipment.shippingMethod.carrier.name }}
            </DescriptionItem>
            <DescriptionItem
              v-if="showReturnInStore"
              :term="$t('customer.status:shipment:shipping-method')"
            >
              {{ $t('customer.status:shipment:return-in-store') }}
            </DescriptionItem>
            <DescriptionItem v-if="isManualShipment">
              <Badge color="blue">
                {{ isManualShipment ? $t('customer.status:shipments:manual-shipment') : shipment.shippingMethod.name }}
              </Badge>
            </DescriptionItem>
          </DescriptionList>
          <div class="hidden md:block">
            <ButtonGroup>
              <Button
                v-if="helpscoutId && shipment.shippingMethod.type !== App.Enums.ShippingMethodType.ReturnInStore"
                @click="handleSupport"
              >
                {{ $t('customer.status:shipment:support') }}
              </Button>
              <Button
                v-if="showOpenLabelButton"
                no-wrap
                :color="brandColor"
                @click="openLabel"
              >
                {{ $t('customer.status:shipment:open-label') }}
              </Button>
            </ButtonGroup>
          </div>
          <div class="relative block md:hidden">
            <EllipsisVerticalIcon
              class="w-6 h-6 text-slate-500"
              @click="togglePopover"
            />
            <Popover
              :open="popoverOpen"
              :placement="PopoverPlacement.BottomEnd"
              @close="closePopover"
            >
              <ActionList :items="popoverItems" />
            </Popover>
          </div>
        </div>
      </ResourceItem>
      <ResourceItem v-if="shipment.pickUpTimeSlot">
        <TextContainer>
          <TextStyle strong>
            {{ $t('customer.status:shipment:pickup-time-slot') }}
          </TextStyle>
        </TextContainer>
        <TextContainer>
          <span class="text-slate-500 mt-2 block">{{ shipment.pickUpTimeSlot }}</span>
        </TextContainer>
      </ResourceItem>
      <template v-if="isSuccessful">
        <ResourceItem
          v-if="shipment.hasCustomsDocuments"
        >
          <div class="flex flex-col space-y-3 text-sm">
            <Heading :size="HeadingSize.Small">
              {{ $t('customer.status:shipment:customs-documents') }}
            </Heading>

            <template
              v-for="documentType in shipment.customsDocuments"
              :key="documentType.value"
            >
              <Link
                native
                target="_blank"
                :href="$route('panel.shipments.document', { shipment: shipment.id, type: documentType.value })"
              >
                <span class="flex space-x-2 items-center">
                  <ArrowDownTrayIcon class="h-4 w-4" />
                  <span>{{ documentType.label }}</span>
                </span>
              </Link>
            </template>
          </div>
        </ResourceItem>
        <ResourceItem
          v-if="shipment.instruction || svg"
        >
          <div class="flex flex-col space-y-12 sm:space-y-0 sm:flex-row sm:space-x-12">
            <div class="w-full">
              <div
                v-if="shipment.barcode !== null"
                class="w-full space-y-2"
              >
                <Heading :size="HeadingSize.Small">
                  {{ $t('customer.status:shipment:information-for-store') }}
                </heading>

                <div class="flex flex-col items-center max-w-60 space-y-2">
                  <img
                    :src="`data:image/png;base64,${shipment.barcode.content}`"
                    class="h-16 w-60"
                    alt="Barcode"
                  >
                  <span class="text-sm text-center">{{ shipment.barcode.barcode }}</span>
                </div>
              </div>

              <div
                v-if="shipment.instruction"
                class="prose text-sm w-full"
              >
                <DescriptionList>
                  <DescriptionItem :term="$t('customer.status:shipment:shipping-instructions')">
                    <span v-html="shipment.instruction.renderedContent" />
                  </DescriptionItem>
                </DescriptionList>
              </div>
            </div>
            <div
              v-if="shipment.showQrCode && svg"
              class="w-full flex justify-center sm:justify-end"
              v-html="svg"
            />
          </div>
        </ResourceItem>
        <ResourceItem
          v-for="shipmentLine in shipment.shipmentLines"
          :key="shipmentLine.id"
          class="p-4 sm:p-6"
        >
          <OrderStatusReturnItem
            :return-order-intent="returnOrderIntent"
            :return-order-item="shipmentLine.returnOrderItem"
            :visible-product-details="visibleProductDetails"
          />
        </ResourceItem>
        <ResourceItem v-if="!isEmpty(answers)">
          <div class="space-y-2">
            <TextStyle
              strong
              :shade="TextStyleShade.Dark"
            >
              {{ $t('customer.status:shipment:return-order-questions') }}
            </TextStyle>
            <OrderStatusAnswerList :answers="answers" />
          </div>
        </ResourceItem>
      </template>
      <template v-if="!isSuccessful">
        <OrderStatusShipmentForm
          :return-order-intent="returnOrderIntent"
          :address-data="addressData"
          :country-name="countryName"
          :shipment="shipment"
        />
      </template>
    </ResourceList>
  </div>
</template>
