<script lang="ts" setup>
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { computed, ref } from 'vue';
import { Modal, TextStyle, TextStyleFontSize, TextStyleShade } from '@app/customer/Components';
import { type Types } from '@app/shared/types/generated-v2';
import {
  BuildingStorefrontIcon,
  MapPinIcon,
} from '@heroicons/vue/24/outline';

type ReturnInStoreShippingMethodViewModel = Types['App.Http.ViewModels.Customer.ReturnInStoreShippingMethodViewModel'];

const props = defineProps<{
  returnInStoreShippingMethod: ReturnInStoreShippingMethodViewModel;
  isSelected: boolean;
}>();

const emit = defineEmits<{
  select: [formShippingMethodId: string];
}>();

function selectFormShippingMethod() {
  emit('select', props.returnInStoreShippingMethod.id);
}

const borderClassList = computed(() => {
  return [
    { 'border-blue-500 bg-blue-50': props.isSelected },
    { 'hover:border-slate-400': !props.isSelected },
  ];
});

const isModelOpen = ref(false);

function showModal() {
  isModelOpen.value = true;
}

function closeModal() {
  isModelOpen.value = false;
}

const distanceLabelForNearestStore = computed<string>(() =>
  props.returnInStoreShippingMethod.nearbyStoreLocations.length > 0 && props.returnInStoreShippingMethod.nearbyStoreLocations[0].distanceLabel !== null
    ? `(${props.returnInStoreShippingMethod.nearbyStoreLocations[0].distanceLabel})`
    : '');
</script>

<template>
  <div
    tabindex="0"
    class="flex flex-col space-y-4 border-slate-300 border rounded-lg shadow-sm p-4 text-sm cursor-pointer outline-blue-500"
    :class="borderClassList"
    @click="selectFormShippingMethod"
    @keyup.enter="selectFormShippingMethod"
  >
    <div class="flex items-center w-full font-medium text-sm">
      <span class="truncate inline-block">
        {{ returnInStoreShippingMethod.label }}
      </span>

      <div
        v-if="isSelected"
        class="ml-auto"
      >
        <CheckCircleIcon class="size-5 fill-blue-500 ml-2" />
      </div>
    </div>

    <div class="flex space-x-4">
      <BuildingStorefrontIcon class="size-10 text-slate-500" />
      <div class="flex flex-col space-y-1">
        <TextStyle :font-size="TextStyleFontSize.Small">
          <div class="flex space-x-1 items-center">
            <MapPinIcon class="size-5 flex-shrink-0" />

            <span>
              {{ returnInStoreShippingMethod.description }} {{ distanceLabelForNearestStore }}
            </span>
          </div>
        </TextStyle>

        <TextStyle
          v-if="returnInStoreShippingMethod.showNearbyStoreLocationsButton"
        >
          <div class="flex space-x-1 items-center mt-1">
            <button
              type="button"
              class="text-brand-500 dark:text-blue-500 cursor-pointer hover:underline"
              @click="showModal"
            >
              {{ $t('customer.shipping:return-in-store:show-nearby-stores-button:label') }} ↗
            </button>
          </div>
        </TextStyle>
      </div>
    </div>
  </div>
  <Modal
    v-if="returnInStoreShippingMethod.showNearbyStoreLocationsButton"
    :title="$t('customer.shipping:return-in-store:nearby-store-locations:modal:label')"
    :open="isModelOpen"
    title-alignment="left"
    :icon="null"
    @close="closeModal"
  >
    <div class="grid grid-cols-1 divide-y">
      <div
        v-for="nearbyStoreLocation in returnInStoreShippingMethod.nearbyStoreLocations"
        :key="nearbyStoreLocation.id"
        class="flex space-x-4 items-center py-4"
      >
        <img
          v-if="nearbyStoreLocation.imageSrc"
          class="size-10 rounded-md object-cover flex-shrink-0"
          :src="nearbyStoreLocation.imageSrc"
          :alt="nearbyStoreLocation.name"
        >

        <div
          v-if="!nearbyStoreLocation.imageSrc"
          class="size-10 grid place-items-center rounded flex-shrink-0"
        >
          <BuildingStorefrontIcon class="size-8 text-slate-800" />
        </div>
        <div class="w-full flex justify-between items-center">
          <div class="flex flex-col text-sm">
            <span class="flex space-x-2">
              <TextStyle
                :shade="TextStyleShade.Dark"
                strong
              >
                {{ nearbyStoreLocation.name }}
              </TextStyle>
              <TextStyle
                v-if="nearbyStoreLocation.distanceLabel !== null"
                :shade="TextStyleShade.Subdued"
              >
                {{ nearbyStoreLocation.distanceLabel }}
              </TextStyle>
            </span>

            <a
              :href="nearbyStoreLocation.url"
              class="text-brand-500 dark:text-blue-500 cursor-pointer hover:underline"
              target="_blank"
            >
              {{ nearbyStoreLocation.completeAddress }} ↗
            </a>
          </div>
        </div>
      </div>
    </div>
  </Modal>
</template>
