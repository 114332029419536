import axios, { AxiosInstance } from 'axios';
import { getEnv } from '@app/shared/Utils';
import { ToastVariant } from '../Components';
import { useToast } from './useToast';
import { createI18n } from '../Utils';
import { computed } from 'vue';
import { usePage } from '@inertiajs/vue3';

const language = computed<string>(() => {
  return usePage().props.language as string;
});

export function useApi(): AxiosInstance {
  const axiosInstance = axios.create({
    baseURL: getEnv('api_url'),
    withCredentials: true,
    withXSRFToken: true,
  });

  const { t } = createI18n(language.value).global;
  const { emitToastEvent } = useToast();

  axiosInstance.interceptors.response.use(
    (response) => response,
    function (error) {
      if (navigator.onLine) {
        return Promise.reject(error);
      }

      emitToastEvent({
        title: t('customer.global:offline'),
        message: t('customer.global:internet-disconnected'),
        variant: ToastVariant.Critical,
      });
    });

  return axiosInstance;
}
