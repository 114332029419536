<script lang="ts" setup>
import { CheckCircleIcon } from '@heroicons/vue/24/solid';
import { computed } from 'vue';
import { theme } from '@app/customer/Composables/useTheme';

const props = defineProps<{
  label: string;
  surcharge?: number;
  formattedSurcharge?: string;
  checked: boolean;
}>();

const emit = defineEmits(['select']);

function select() {
  emit('select');
}

const borderClassList = computed(() => {
  return [
    { 'border-blue-500 bg-blue-50': props.checked },
    { 'hover:border-slate-400': !props.checked },
    theme('outline'),
  ];
});
</script>

<template>
  <div
    tabindex="0"
    class="border-slate-300 border rounded-lg shadow-sm p-4 space-y-4 text-sm cursor-pointer"
    :class="borderClassList"
    @click="select"
    @keyup.enter="select"
  >
    <div class="flex space-x-2 items-center w-full text-sm">
      <div>
        <CheckCircleIcon
          v-if="checked"
          class="h-5 w-5 fill-blue-500"
        />
        <div
          v-else
          class="w-5 h-5 rounded-full outline outline-gray-400 outline-1 outline-offset-[-3px]"
        />
      </div>

      <div class="flex justify-between items-center w-full">
        <div class="flex items-center space-x-2">
          <span class="text-sm">{{ label }}</span>
        </div>
        <div>
          <span
            v-if="surcharge"
            class="text-sm text-gray-600"
          >
            {{ formattedSurcharge }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>
