import * as Types from '@app/panel/types/generated';
import { getEnv } from '@app/shared/Utils';
import { onMounted, onUnmounted, ref } from 'vue';
import { useToast } from '../Composables/useToast';
import { useI18n } from 'vue-i18n';
import { ToastVariant } from '../Components';

type ReturnOrderIntentViewModel = Types.App.Models.ViewModels.ReturnOrderIntentViewModel;

export function beaconService() {
  const beaconReady = ref<boolean>(false);

  const { emitToastEvent } = useToast();
  const { t } = useI18n();

  const helpscoutId = getEnv('helpscout_id');

  async function init(intent: ReturnOrderIntentViewModel, customerName: string | null, customerEmail: string | null, signature: string): Promise<void> {
    if (!helpscoutId) {
      return;
    }

    window.Beacon('init', helpscoutId);

    window.Beacon('prefill', {
      name: customerName,
      email: customerEmail,
      fields: [
        {
          id: 43957, // Shop name
          value: intent.form.defaultFormLocale.shopName,
        },
        {
          id: 43901, // Return reference
          value: intent.returnOrderId,
        },
        {
          id: 44606, // Returnless platform version
          value: 'V2', // option
        },
      ],
    });

    window.Beacon('identify', {
      name: customerName,
      email: customerEmail,
      signature,
    });
  }

  function toggle(): void {
    if (!beaconReady.value) {
      emitToastEvent({
        message: t('customer.global:error-message'),
        variant: ToastVariant.Critical,
      });
    }

    if (beaconReady.value) {
      window.Beacon('toggle');
    }
  }

  onMounted(() => window.Beacon('on', 'ready', () => {
    beaconReady.value = true;
  }));

  onUnmounted(() => window.Beacon('destroy'));

  return {
    init,
    helpscoutId,
    toggle,
  };
}
