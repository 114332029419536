<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps({
  first: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    required: true,
  },

  last: {
    type: Boolean,
    default: false,
  },

  completed: {
    type: Boolean,
    default: false,
  },
});

const lineClassList = computed(() => {
  return [
    { 'bg-slate-300': !props.completed },
    { 'bg-slate-800': props.completed },
  ];
});

const textClassList = computed(() => {
  return [
    { 'text-slate-500': !props.completed },
    { 'text-slate-800': props.completed },
  ];
});
</script>

<template>
  <div class="flex flex-col justify-between h-10 flex-1 shrink-0 basis-auto">
    <div
      class="h-1.5 sm:h-2 rounded-full"
      :class="lineClassList"
    />
    <span
      class="text-xs sm:text-sm"
      :class="textClassList"
    >{{ label }}</span>
  </div>
</template>
