import { createI18n } from 'vue-i18n';
import lang from '../../lang';

export default (locale: string) => {
  return createI18n({
    legacy: false,
    locale: locale,
    fallbackLocale: 'en',
    messages: window.__conf.database_translations ? window.translations : lang,
  });
};
