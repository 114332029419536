import { readonly, ref } from 'vue';

export function useToggle(initialValue = false) {
  const value = ref<boolean>(initialValue);

  function toggle(): void {
    value.value = !value.value;
  }

  function activate(): void {
    value.value = true;
  }

  function deactivate(): void {
    value.value = false;
  }

  return {
    activate,
    deactivate,
    toggle,
    value: readonly(value),
  };
}
