import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

export function toLocalDateTime(datetime: string): string {
  return dayjs.utc(datetime).local().format('lll');
}
