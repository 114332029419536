import { round } from 'lodash';

export default class Dimension {
  private readonly value: number;

  private constructor(value: number) {
    const numericValue = Number(value);

    if (numericValue < 0) {
      throw Error('The dimension cannot be a negative number.');
    }

    if (isNaN(numericValue)) {
      throw Error('The dimension cannot be converted to a number.');
    }

    this.value = value;
  }

  public getValue(): number {
    return this.value;
  }

  public toCentimeters(): number {
    return round(this.value / 10, 2);
  }

  public static fromMillimeters(value: number): Dimension {
    return new Dimension(value);
  }

  public static fromCentimeters(value: number): Dimension {
    return new Dimension(value * 10);
  }
}
