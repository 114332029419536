export class SalesOrderData {
  public constructor(
    public currency: string,
    public order_date: string,
    public order_number: string,
    public delivery_age: number | null,
    public payment_method: string | null,
    public shipping_method: string | null,
    public status: string | null,
    public meta_data: Record<string, any>,
  ) {
  }
}
