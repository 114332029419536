<script lang="ts" setup>
import { computed } from 'vue';
import { useUniqueId } from '../../../Composables/useUniqueId';
import { theme } from '../../../Composables/useTheme';

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },

  value: {
    type: String,
    required: true,
  },

  name: {
    type: String,
    default: null,
  },

  id: {
    type: String,
    default: null,
  },

  label: {
    type: String,
    required: true,
  },

  imageUrl: {
    type: String,
    default: null,
  },

  alt: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['update:modelValue']);

const elementId = computed(() => props.id || useUniqueId('searchField'));

const checked = computed(() => {
  return props.modelValue === props.value;
});

const onRadioButtonChange = (): void => {
  emit('update:modelValue', props.value);
};
</script>

<template>
  <div
    tabindex="0"
    class="border border-slate-300 p-4 rounded-md cursor-pointer"
    :class="[
      {'bg-blue-50': checked, 'hover:border-slate-400': ! checked},
      theme('outline'),
    ]"
    @click="onRadioButtonChange"
    @keyup.enter="onRadioButtonChange"
  >
    <div class="w-fit cursor-pointer flex items-center space-x-2 text-sm">
      <input
        :id="elementId"
        tabindex="-1"
        type="radio"
        :name="name"
        :value="value"
        :checked="checked"
        class="h-4 w-4 cursor-pointer accent-blue-500 focus:outline-none"
      >

      <img
        v-if="imageUrl"
        class="h-full w-6"
        :src="imageUrl"
        :alt="alt"
        loading="lazy"
      >

      <label
        :for="id"
        class="block w-fit text-slate-700 cursor-pointer"
      >
        {{ label }}
      </label>
    </div>
  </div>
</template>
