<script lang="ts" setup>
import { computed } from 'vue';
import { Link } from '@inertiajs/vue3';
import { theme } from '../../../Composables/useTheme';

const props = defineProps({
  href: {
    type: String,
    required: false,
    default: '#',
  },

  native: {
    type: Boolean,
    default: false,
  },

  noUnderline: {
    type: Boolean,
    default: false,
  },
});

const componentType = computed(() => {
  return props.native ? 'a' : Link;
});
</script>

<template>
  <component
    :is="componentType"
    :class="[
      'text-blue-600',
      'font-medium',
      'cursor-pointer',
      'text-md',
      { 'hover:underline': ! noUnderline },
      theme('outline'),
    ]"
    :href="href"
    v-bind="$attrs"
  >
    <slot />
  </component>
</template>
