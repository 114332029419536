<script lang="ts" setup>
import {
  Button,
  Modal,
} from '@app/customer/Components';
import { PaperClipIcon } from '@heroicons/vue/24/outline';
import { useFileManager } from '@app/customer/Composables/useFileManager';
import { UploadedFile } from '../../UploadedFile';
import { computed, inject, ref, watch } from 'vue';
import { useUpload } from '@app/customer/Composables/useUpload';
import { UploadedFileInterface } from '@app/customer/Composables/useFileManager/types';
import { has, isEmpty } from 'lodash';
import { FileUpload } from '@app/customer/Components/FileUpload';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { useI18n } from 'vue-i18n';

const props = defineProps<{
  attachments: any[];
}>();

const FILE_MANAGER_KEY = 'attachments';
const MAX_FILES_TO_UPLOAD = 5;

const isOpen = ref(false);

function openModal(): void {
  isOpen.value = true;
}

function closeModal(): void {
  isOpen.value = false;
}

const emit = defineEmits<{
  (e: 'attach', files: File[]): void;
}>();

const attachmentFileSizeLimit = inject<number>('attachmentFileSizeLimit');
const attachmentFileTypes = inject<string[]>('attachmentFileTypes');

const {
  addFiles,
  clearFiles,
  error,
  removeFile,
  setError,
  uploadedFiles,
} = useFileManager(MAX_FILES_TO_UPLOAD, attachmentFileSizeLimit, attachmentFileTypes);

const hasUploadedFiles = computed<boolean>(() => {
  return Object.keys(uploadedFiles.value).length > 0;
});

const files = computed(() => Object.keys(uploadedFiles.value).flatMap((key) => uploadedFiles.value[key]));

const errors = computed(() => Object.values(error.value).filter((err) => err !== null));

const { t } = useI18n();

async function uploadFileToVapor(file: UploadedFileInterface): Promise<object | null> {
  const { upload } = useUpload();

  try {
    const response = await upload(file.file, (progress) => file.progress = progress);

    if (!has(response, 'uuid')) {
      setError(FILE_MANAGER_KEY, t('customer.global:error-message'));
      return null;
    }

    const fileData = {
      filename: file.name,
      previewUrl: file.url,
      ...response,
    };

    return fileData;
  } catch (error) {
    setError(FILE_MANAGER_KEY, error);
    return null;
  }
}

async function uploadFilesToVapor() {
  const promises = [];

  files.value.forEach((file) => {
    promises.push(() => uploadFileToVapor(file));
  });

  await Promise.all(promises.map((f) => f()))
    .then((response) => {
      if (response.some((item) => item === null)) {
        return;
      }

      emit('attach', response);
      closeModal();
    });
}

watch(() => props.attachments, (newValue) => {
  if (isEmpty(newValue.length)) {
    clearFiles();
  }
});

function uploadFiles(files: File[]) {
  addFiles(FILE_MANAGER_KEY, files);
}

const buttonColor = useBrandColor();

</script>

<template>
  <Button
    type="button"
    @click="openModal"
  >
    <PaperClipIcon
      class="size-5 cursor-pointer text-slate-400 hover:text-slate-500"
    />
    <span class="ml-2">
      {{ $t('customer.status:notes:upload-an-attachment') }}
    </span>
  </Button>

  <Modal
    :title="$t('customer.status:notes:attachment:title')"
    :open="isOpen"
    @close="closeModal"
  >
    <div class="mt-3 flex flex-col space-y-3">
      <FileUpload
        :allowed-file-types="attachmentFileTypes"
        :file-size-limit="attachmentFileSizeLimit"
        :error="errors[0] ?? null"
        :on-files-upload="uploadFiles"
        multiple
      />

      <div
        v-if="hasUploadedFiles"
        class="flex flex-col space-y-2"
      >
        <UploadedFile
          v-for="file in files"
          :key="file.id"
          v-bind="file"
          @remove="() => removeFile(FILE_MANAGER_KEY, file.id)"
        />
      </div>
    </div>

    <template #footer>
      <div class="flex w-full space-x-3">
        <Button
          full-width
          @click="closeModal"
        >
          {{ $t('customer.status:notes:attachment:cancel') }}
        </Button>
        <Button
          full-width
          :color="buttonColor"
          @click="uploadFilesToVapor"
        >
          {{ $t('customer.status:notes:attachment:attach') }}
        </Button>
      </div>
    </template>
  </Modal>
</template>
