<script lang="ts" setup>
import {
  AttachmentItem,
  AttachmentList,
  Link,
  TextStyle,
  TextStyleShade,
} from '@app/customer/Components';
import { useLocale } from '@app/customer/Composables/useLocale';
import { download } from '@app/shared/Utils';
import { type Types } from '@app/shared/types/generated-v2';
import { isEmpty } from 'lodash';

type ReturnQuestionAnswerViewModel = Types['App.Models.ViewModels.Customer.ReturnQuestionAnswerViewModel'];

defineProps<{
  answers: ReturnQuestionAnswerViewModel[];
}>();

const { currentLocale } = useLocale();
</script>

<template>
  <div class="space-y-2">
    <div
      v-for="answer in answers"
      :key="answer.id"
      class="flex flex-col"
    >
      <TextStyle
        :shade="TextStyleShade.Dark"
        strong
      >
        {{ answer.question }}
      </TextStyle>
      <div>
        <TextStyle :shade="TextStyleShade.Subdued">
          <span v-if="answer.answer">{{ answer.answer }}</span>
          <span v-if="answer.option">{{ answer.option.translations[currentLocale.id] }}</span>
        </TextStyle>

        <div
          v-if="!isEmpty(answer.attachments)"
          class="mt-1"
        >
          <AttachmentList>
            <AttachmentItem
              v-for="attachment in answer.attachments"
              :key="attachment.id"
            >
              <span class="break-all">{{ attachment.filename }}</span>

              <template #actions>
                <Link
                  native
                  @click.prevent="() => download(attachment.path, attachment.filename)"
                >
                  {{ $t('customer.status:return-item:download') }}
                </Link>
              </template>
            </AttachmentItem>
          </AttachmentList>
        </div>
      </div>
    </div>
  </div>
</template>
