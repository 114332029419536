export class ProductData {
  public constructor(
    public barcode: string | null,
    public hs_code: string | null,
    public brand: string | null,
    public height: number,
    public length: number,
    public width: number,
    public name: string | null,
    public sku: string,
    public supplier: string,
    public category: string[],
  ) {
  }
}
