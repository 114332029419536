import { computed, ref } from 'vue';
import { emitter, GlobalEventType } from '../Utils';
import { ToastVariant } from '../Components';
import { theme } from './useTheme';

export interface ToastNotification {
  id?: string;
  title?: string;
  message: string;
  duration?: number;
  variant?: ToastVariant;
}

export function useToast() {
  const toastNotifications = ref<ToastNotification[]>([]);

  const toastIDs = computed(() => {
    return toastNotifications.value.map((item) => {
      return item.id;
    });
  });

  function isPresent(newNotification: ToastNotification): boolean {
    return toastNotifications.value.some((notification) => {
      return notification.id === newNotification.id;
    });
  }

  function startListeningToastEvents() {
    emitter.on(GlobalEventType.ToastNotification, (toast: ToastNotification) => {
      if (!isPresent(toast)) {
        toastNotifications.value.push(toast);
      }
    });
  }

  function emitToastEvent(toast: ToastNotification): void {
    toast.id = toast.id ?? Date.now().toString();
    toast.duration = toast.duration ?? parseInt(theme('notification-duration')),
    toast.variant = toast.variant ?? ToastVariant.Success;

    emitter.emit(GlobalEventType.ToastNotification, toast);
  }

  function closeToast(id: string): void {
    toastNotifications.value = toastNotifications.value.filter((notification) => {
      return notification.id !== id;
    });
  }

  return {
    closeToast,
    emitToastEvent,
    startListeningToastEvents,
    toastIDs,
    toastNotifications,
  };
}
