<script lang="ts" setup>
import {
  Button,
  Heading,
} from '@app/customer/Components';
import { AppLayout } from '@app/customer/Layouts';
import { type Types } from '@app/shared/types/generated-v2';
import { computed, ref } from 'vue';
import Checkbox from '@app/customer/Components/Checkbox/components/Checkbox.vue';
import { round } from 'lodash';
import { useBrandColor } from '@app/customer/Composables/useBrandColor';
import { router } from '@inertiajs/vue3';

type ShowPaymentRequestViewModel = Types['App.Http.ViewModels.Customer.ShowPaymentRequestViewModel'];
type PaymentRequestLineViewModel = Types['App.Http.ViewModels.Customer.PaymentRequestLineViewModel'];

const props = defineProps<ShowPaymentRequestViewModel>();

const selectedPaymentRequestLineIds = ref<string[]>(props.selectedPaymentRequestLineIds);

function togglePaymentLine(paymentRequestLine: PaymentRequestLineViewModel) {
  if (paymentRequestLine.required) {
    return;
  }

  if (selectedPaymentRequestLineIds.value.includes(paymentRequestLine.id)) {
    selectedPaymentRequestLineIds.value = selectedPaymentRequestLineIds.value.filter((id) => id !== paymentRequestLine.id);
  } else {
    selectedPaymentRequestLineIds.value.push(paymentRequestLine.id);
  }
}

function getPaymentRequestLineTotal(paymentRequestLine: PaymentRequestLineViewModel): number {
  return round((paymentRequestLine.quantity * paymentRequestLine.price) / 100, 2);
}

function formatCurrency(price: number): string {
  const formatter = new Intl.NumberFormat(props.isoCode.replace('_', '-'), {
    style: 'currency',
    currency: props.currencyCode,
  });

  return formatter.format(price);
}

const total = computed(() => {
  return selectedPaymentRequestLineIds.value.reduce((acc: number, id: string) => {
    const paymentRequestLine = props.paymentRequestLines.find((paymentRequestLine) => paymentRequestLine.id === id);
    return acc + getPaymentRequestLineTotal(paymentRequestLine);
  }, 0);
});

const buttonColor = useBrandColor();

function save() {
  router.post(route('customer.intent.selected-payment-request-lines.update', {
    paymentRequest: props.id,
  }), {
    selected_payment_request_lines: selectedPaymentRequestLineIds.value,
  }, {
    preserveScroll: true,
  });
}
</script>

<template>
  <AppLayout>
    <div class="max-w-3xl mx-auto">
      <div class="flex flex-col sm:flex-row sm:space-x-4 justify-between border-b border-slate-100 pb-6 mb-4">
        <Heading>
          {{ $t('customer.payment-request:show:title') }}
        </Heading>
      </div>

      <div class="flex flex-col space-y-4">
        <span class="italic text-slate-500 text-sm w-2/3 bg-slate-100/50 border-slate-100 p-4 border rounded-sm">
          {{ description }}
        </span>

        <div class="flex flex-col space-y-2">
          <div
            v-for="paymentRequestLine in paymentRequestLines"
            :key="paymentRequestLine.id"
            class="flex justify-between border-slate-300 border rounded-lg shadow-sm p-4 text-sm cursor-pointer hover:bg-slate-50/50"
            @click="togglePaymentLine(paymentRequestLine)"
          >
            <div class="flex items-center">
              <Checkbox
                :model-value="selectedPaymentRequestLineIds.includes(paymentRequestLine.id)"
                :disabled="paymentRequestLine.required"
              />
              <span>{{ paymentRequestLine.description }}</span>
            </div>
            <span class="text-slate-500 tracking-wide">
              {{ formatCurrency(getPaymentRequestLineTotal(paymentRequestLine)) }}
            </span>
          </div>
        </div>

        <div class="flex items-center justify-end">
          <div
            class="text-right text-sm font-semibold text-gray-900 sm:table-cell p-4"
          >
            {{ $t('customer.payment-request:show:total') }}
          </div>
          <div class="text-right text-sm font-semibold text-gray-900 p-4">
            {{ formatCurrency(total) }}
          </div>
        </div>

        <div class="flex justify-end">
          <Button
            :color="buttonColor"
            type="submit"
            :disabled="selectedPaymentRequestLineIds.length === 0"
            @click="save"
          >
            {{ $t('customer.payment-request:show:select-payment-method') }}
          </Button>
        </div>
      </div>
    </div>
  </AppLayout>
</template>
